import React,{useState} from 'react'
import House from "../assets/images/house-1900-1-1-1.webp"
import getInTouchImg from "../assets/images/image-box-3-large-1-1-1.webp"


function HomeValuation() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        city: "",
        state: "",
        email: "",
        mobile: "",
        message: "",
        zip:"",
        country:"",
        address:"",
        consent: false,
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        city: "",
        state: "",
        email: "",
        mobile: "",
        message: "",
        zip:"",
        country:"",
        address:"",
        consent: "",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        // Name validation
        if (!formData.firstName) {
            formErrors.firstName = "First name is required";
            isValid = false;
        }
        if (!formData.lastName) {
            formErrors.lastName = "Last name is required";
            isValid = false;
        }

        // City and State validation
        if (!formData.city) {
            formErrors.city = "City is required";
            isValid = false;
        }
        if (!formData.state) {
            formErrors.state = "State is required";
            isValid = false;
        }

        // Email validation
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!formData.email) {
            formErrors.email = "Email is required";
            isValid = false;
        } else if (!emailPattern.test(formData.email)) {
            formErrors.email = "Please enter a valid email address";
            isValid = false;
        }

        // Mobile validation
        if (!formData.mobile) {
            formErrors.mobile = "Mobile number is required";
            isValid = false;
        } else if (formData.mobile.length !== 10) {
            formErrors.mobile = "Mobile number must be 10 digits";
            isValid = false;
        } else if (!/^\d{10}$/.test(formData.mobile)) {
            formErrors.mobile = "Mobile number must contain only digits";
            isValid = false;
        }

        // Message validation
        if (!formData.message) {
            formErrors.message = "Message is required";
            isValid = false;
        }

        if (!formData.zip) {
            formErrors.zip = "zip is required";
            isValid = false;
        }

        if (!formData.address) {
            formErrors.address = "address is required";
            isValid = false;
        }

        if (!formData.country) {
            formErrors.country = "country is required";
            isValid = false;
        }


        // Consent validation
        if (!formData.consent) {
            formErrors.consent = "You must consent to the GDPR terms";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate form
        if (validateForm()) {
            // Send form data or handle form submission here

            setFormData({
                firstName: "",
                lastName: "",
                city: "",
                state: "",
                email: "",
                mobile: "",
                message: "",
                zip:"",
                country:"",
                address:"",
                consent: "",
            })
        }

    };
  return (
    <div>
    <div className="relative bg-cover bg-center animate-fadeIn h-[650px] flex items-center " style={{
        backgroundImage:
            `url(${House})`,
    }}>
        <div className="absolute inset-0 bg-black bg-opacity-40"></div>

        <div className="relative text-center w-full">


            <h1 style={{color:"#FFFFFF",fontFamily:'"Bodoni Moda","Sans-serif"',fontWeight:"400",textShadow:" 0px 0px 10px rgba(0,0,0,0.3",}}  className='text-[65px] max-sm:text-[35px] leading-3 max-sm:leading-none'>Do want to know what's</h1>
               
           
            <h3 style={{color:"#FFFFFF",fontFamily:'"Bodoni Moda","Sans-serif"',fontWeight:"400",textShadow:" 0px 0px 10px rgba(0,0,0,0.3"}}   className='mt-[50px] text-[65px] max-sm:text-[35px] max-sm:mt-0 leading-3  max-sm:leading-none'>your property worth?
            </h3>

        </div>


    </div>
    
    
    <div className='mt-7 mb-20 relative'>
    <div className="absolute -top-7 w-[70%] h-[1028px] bg-Stone-100 flex right-0 max-sm:w-[100%]"></div>
    <div className="flex flex-col md:flex-row items-center md:items-start min-h-screen bg-pink-50 px-28 pt-10 max-sm:px-2">

        {/* Image Section */}
        <div className="md:w-1/2 z-10">
            <img
                src={getInTouchImg} // Replace with the actual image path
                alt="Luxury Kitchen"
                className="w-full h-[880px] object-cover rounded-xl max-sm:w-[100%]"
            />
        </div>
        {/* Text Section */}
        <div className="md:w-1/2 mt-10 py-10 pr-0 text-start bg-white rounded-xl z-10  w-[100%] flex justify-center max-sm:py-0">
<div className="bg-white p-8 rounded-lg w-full border border-gray-200 max-w-lg flex flex-col ">
    <h1 style={{ fontSize: "40px",fontFamily:"'Bodoni Moda','Sans-serif'" ,fontWeight:"400px", textTransform:"capitalize"}} className="text-gray-800 mb-6">
    Get your home Estimation
    </h1>
    <form className="space-y-4 bg-gray-100 p-5 rounded-lg border" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 ">
            <div>
            <label>
                FirstName
                </label>
                <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="Name"
                    // className="border border-gray-300 rounded-lg p-2 w-full"
                    className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                        errors.firstName ? "border-Red-700" : ""
                      }`}
                />
                {errors.firstName && (
                    <p className="text-Red-700 text-sm">{errors.firstName} *</p>
                )}
            </div>
            <div>
            <label>LastName</label>
                <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                        errors.lastName ? "border-Red-700" : ""
                      }`}
                />
                {errors.lastName && (
                    <p className="text-Red-700 text-sm">{errors.lastName} *</p>
                )}
            </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
            <div>
            <label>Email *</label>
                <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="email"
                    className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                        errors.email ? "border-Red-700" : ""
                      }`}
                />
                {errors.email && (
                    <p className="text-Red-700 text-sm">{errors.email} *</p>
                )}
            </div>
            <div>
            <label>Mobile *</label>
                <input
                    type="number"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    placeholder="Mobile"
                    className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                        errors.state ? "border-Red-700" : ""
                      }`}
                />
                {errors.mobile && (
                    <p className="text-Red-700 text-sm">{errors.mobile} *</p>
                )}
            </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
            <div>
            <label>Country</label>
                <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    placeholder="country"
                    className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                        errors.country ? "border-Red-700" : ""
                      }`}
                />
                {errors.country && (
                    <p className="text-Red-700 text-sm">{errors.country} *</p>
                )}
            </div>
            <div>
            <label>State</label>
                <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    placeholder="State"
                    className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                        errors.state ? "border-Red-700" : ""
                      }`}
                />
                {errors.state && (
                    <p className="text-Red-700 text-sm">{errors.state} *</p>
                )}
            </div>
            <div>
            <label>City</label>
                <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="City"
                    className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                        errors.city ? "border-Red-700" : ""
                      }`}
                />
                {errors.city && (
                    <p className="text-Red-700 text-sm">{errors.city} *</p>
                )}
            </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
            <div>
            <label>Address</label>
               
                <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="address"
                    className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                        errors.address ? "border-Red-700" : ""
                      }`}
                />
                {errors.address && (
                    <p className="text-Red-700 text-sm">{errors.address} *</p>
                )}
            </div>
            <div>
            <label>Zip</label>
                <input
                    type="text"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                    placeholder="zip"
                    className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                        errors.zip ? "border-Red-700" : ""
                      }`}
                />
                {errors.zip && (
                    <p className="text-Red-700 text-sm">{errors.zip} *</p>
                )}
            </div>
        </div>
                
        
        <div>
        <label>Message *</label>
            <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Type your message"
                rows={4}
                className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                    errors.message ? "border-Red-700" : ""
                  }`}
            />
            {errors.message && (
                <p className="text-Red-700 text-sm">{errors.message} *</p>
            )}
        </div>
        <div className="  ">
            <div className='flex  items-center gap-2 '>
            <input
                type="checkbox"
                name="consent"
                checked={formData.consent}
                onChange={handleChange}
                className="w-4 h-4 text-start"
            />
            <label htmlFor="consent" className="text-sm text-gray-600">
            I consent to having my information saved
            </label>
            </div>
            {errors.consent && (
                <p className="text-Red-700 text-sm">{errors.consent}*</p>
            )}
        </div>
        <button
            type="submit"
            className="w-full bg-[#c5a46d] text-white font-semibold py-2 rounded-lg shadow-md hover:bg-[#b0905e] transition"
        >
            Send Email
        </button>
    </form>
</div>
</div>


    </div>
</div>
   
</div>
  )
}

export default HomeValuation