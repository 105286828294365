import React, { useState, useEffect } from "react";
import video from "../assets/vedios/banner video.mp4";
import mobileVideo from "../assets/vedios/mobileBanner.mp4";
import search from "../assets/images/search (1).png";
import vedioImg from "../assets/images/photo.jpg";
import { getSearchListings } from "../service";
import { LiaAssistiveListeningSystemsSolid } from "react-icons/lia";
import { CiLocationOn } from "react-icons/ci";
import "../App.css";



const BannerVideo = () => {
  const [isVideoLoaded, setVideoLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getSearchListings(searchQuery);
      setListings(response.data.listings || []);
      setFilteredListings(response.data.listings || []);
    } catch (err) {
      console.error("Error fetching listings:", err);
      setError("There was an error fetching the listings.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (input) => {
    // const value = e.target.value;
    setSearchQuery(input);

    // Dynamically filter listings based on input
    const filtered = listings.filter((listing) => {
      const area = listing?.address?.area?.toLowerCase() || "";
      const city = listing?.address?.city?.toLowerCase() || "";
      // const postalCode = listing?.address?.postalCode?.toLowerCase() || '';
      const mlsNumber = listing?.mlsNumber?.toString().toLowerCase() || "";

      return (
        area.includes(input.toLowerCase()) ||
        city.includes(input.toLowerCase()) ||
        // postalCode.includes(value.toLowerCase()) ||
        mlsNumber.includes(input.toLowerCase())
      );
    });

    setFilteredListings(filtered);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div>
      {/* Video Section */}
      <div className="w-full h-2/5 relative  ">
        {/* {!isVideoLoaded && (
          <img
            src={vedioImg}
            alt="Loading placeholder"
            className="max-sm:hidden bg-black/70"
          />
        )} */}
        <video
          src={video}
          autoPlay
          muted
          preload="auto"
          loop
          onLoadedData={() => setVideoLoaded(true)}
          className="hidden lg:block bg-black/70 w-full"
        ></video>
        <video
          src={mobileVideo}
          autoPlay
          loop
          muted
          className="xl:hidden "
        ></video>
        <div className="absolute h-full  inset-0 bg-black opacity-50"></div>

        {/* Search Bar */}
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <h1
            style={{
              color: "#FFFFFF",
              fontFamily: '"Bodoni Moda", "Sans-serif"',
              fontWeight: "400",
              textShadow: "0px 0px 10px rgba(0,0,0,0.3)",
            }}
            className="text-[64px] max-sm:text-[35px]"
          >
            Luxury Real Estate in
          </h1>
          <h1
            style={{
              color: "#FFFFFF",
              fontFamily: '"Bodoni Moda", "Sans-serif"',
              fontWeight: "400",
              lineHeight: "1.1rem",
              textShadow: "0px 0px 10px rgba(0,0,0,0.3)",
            }}
            className="text-[64px] mt-3 max-sm:text-[35px] max-sm:mt-0"
          >
            Toronto
          </h1>
          <div className="relative">

          <div className="p-5 font-open-sans relative flex mt-7 bg-white rounded-lg justify-between pr-5 w-[500px] pl-10 max-sm:p-2 max-sm:w-auto max-sm:mx-0 cursor-pointer">
              <input
                type="text"
                className="placeholder:text-gray-500 w-full placeholder:text-sm max-sm:placeholder:text-sm outline-none"
                placeholder="Search by Area, City, Postal Code or MLS® number"
                value={searchQuery}
                onChange={(e) => handleInputChange(e.target.value)}
                onClick={handleSearch}
                // onKeyPress={handleKeyPress}
              />
              <img
                src={search}
                alt="Search"
                width={25}
                className="cursor-pointer"
              />
                {searchQuery && (
              <div className="mt-3 bg-white max-h-72 overflow-y-auto p-4  rounded-md absolute w-[500px] top-[55px] max-sm:w-[225px] max-sm:top-[37px] left-0 shadow-md animate-slide-down animate-fade-in  ">
                <ul>
                  {filteredListings.length > 0 ? (
                    filteredListings.map((listing, index) => (
                      <li
                        key={index}
                        className="listing-item p-2 border-b border-gray-200 cursor-pointer"
                        onClick={() =>
                          window.open(
                            `/property/${listing.mlsNumber}`,
                            "_blank"
                          )
                        }
                      >
                        <div className="flex flex-col gap-1">
                          <div className="flex gap-2 items-center">
                            <CiLocationOn size={24} className="text-gray-600" />
                            <h3 className="text-gray-800 font-semibold">
                              {listing.address.area} - {listing.address.city}
                            </h3>
                          </div>
                          <div className="flex text-start">
                            <p className="text-gray-500 text-sm ml-10">
                              MLS Number: {listing.mlsNumber}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="listing-item p-2 border-b border-gray-200">
                      <p className="text-gray-500 text-sm">No listings found</p>
                    </li>
                  )}
                </ul>
              </div>
            )}
            </div>
          
          </div>
        </div>
      </div>

      {/* Search Results */}
    </div>
  );
};

export default BannerVideo;
