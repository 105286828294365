import image from '../assets/images/team2.webp';
import { motion } from 'framer-motion';
import FrstBackground from "../assets/images/background-pattern-gold-arch-1-1-1.svg"
import { Link } from 'react-router-dom';


const StoryContent = () => {
    return (
        
        <div className="bg-white">
            <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9, ease: 'easeIn' }}
    >
            <div className="mt-1 px-10 max-sm:px-2 max-lg:px-5">
                <div className='bg-center bg-no-repeat bg-auto  mb-10' style={{ backgroundImage:`url(${FrstBackground})`}}>

                <h1 style={{color:"black",fontFamily:'"Bodoni Moda","Sans-serif"',fontSize:"45px",fontWeight:"400",textTransform:"capitalize", textShadow:" 0px 0px 10px rgba(0,0,0,0.3",}} className='text-[45px] mb-[50px] max-sm:text-[35px] max-sm:mb-[10px]'>The Standard of Excellence</h1>
                <p  className="text-lg text-gray-500">70 YEARS OF BESPOKE SERVICE BEHIND YOUR LISTING.</p>
                </div>

                <div className='flex row gap-14 p-10 max-sm:flex-col max-sm:p-0 max-lg:flex-col max-lg:p-0'>
                    <div className='basis-1/2 rounded-xl w-full flex justify-end  max-lg:justify-center max-lg:w-[100%]'>
                        <img src={image} alt='home' className='object-cover rounded-xl w-[500px] h-[600px] max-lg:w-[100%] '></img>
                    </div>
                    <div className='flex-col justify-center flex py-3 basis-1/2 gap-5'>
                    <p className='text-start text-[#645826] max-sm:text-center'>WHO WE ARE</p>
                    <div>
                        <h1 className='text-black font-serif text-[45px] font-normal  capitalize text-start   max-sm:text-[25px] max-sm:mb-0 max-sm:text-center'>We Help Clients buy and sell </h1>
                        <h1 className='text-start  text-[45px] max-sm:text-[25px] max-sm:text-center' style={{color:"black",fontFamily:'"Bodoni Moda","Sans-serif"',fontWeight:"400",textTransform:"capitalize"}} >House More than 30+ years</h1>
                        </div>
                        <p style={{color:"#464646",fontFamily:'"Open Sans", "Sans-serif"',fontSize:"16px", fontWeight:"400",lineHeight:"1.8em"}} className=' text-justify mr-28 max-sm:text-center max-sm:m-0'>Our founders crafted an approach that had never been tried in Toronto: To focus on fine homes and a discriminating clientele, delivering superior results
                            through a blend of bespoke service and “inside” advice.</p>
                        {/* <p className='mt-9 text-xl text-start'>J&D Realtors possess a sixth sense, a ground-state awareness of luxury home sellers’ and buyers’ requirements and lifestyles – extending well beyond the deal. Anticipation over response. Professional-grade commitment over a hobbyist mentality.
                            And above all, wisdom, diligence and integrity beyond reproach.</p> */}
                            <div className='text-start max-sm:text-center'>
                                <Link to='/ourstory'>
                                <button className='bg-Green-400 text-white font-semibold px-5 py-2 mt-5 rounded-lg'>Learn More</button>
                                </Link>
                            </div>
                    </div>
                </div>
            </div>
            </motion.div>
        </div>
    );
}
export default StoryContent;