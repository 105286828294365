import React,{useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CiPhone, CiGlobe } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { IoIosMail } from "react-icons/io";

function OurStoryProfile() {
    const location = useLocation();
    const { name} = useParams();
  
    const { allmember } = location.state || {};
  
    const selectedmemeber = allmember?.find((member) => String(member.name) === name);

 

      const [formValues, setFormValues] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    
      const [formErrors, setFormErrors] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
      });
    
      // const handleOnClick = () => {
      //   alert("not allowed");
      // };
    
      // Handle form input changes
      const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      };
    
      // Validate form fields
      const validateForm = () => {
        let errors = { name: false, email: false, phone: false, message: false };
        let isValid = true;
    
        // Validate required fields
        if (!formValues.name) {
          errors.name = true;
          isValid = false;
        }
        if (!formValues.email) {
          errors.email = true;
          isValid = false;
        }
        if (!formValues.phone) {
          errors.phone = true;
          isValid = false;
        }
        if (!formValues.message) {
          errors.message = true;
          isValid = false;
        }
    
        setFormErrors(errors);
        return isValid;
      };
      function sendMail() {
        window.location.href = "mailto:example@example.com?subject=Hello&body=This is a test email.";
    }
    
      // Handle form submission
      const handleOnSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
          alert("Form submitted successfully!");
      
          // Reset form fields to empty values
          setFormValues({
            name: "",
            email: "",
            phone: "",
            message: ""
          });
        }
      };
      

  return (
    <div className="py-4 p-4 max-sm:p-0 max-sm:py-0">
     
    <div className="profileSec flex max-sm:flex-col ">
    
      {/* User Image */}
      <div className="userImg bg-white w-[50%] max-sm:w-[100%] md:w-[100%] lg:w-[50%]">
        <img
          src={selectedmemeber?.images || 'default_image_url'} 
          alt="Agent"
          className="userImage h-[250px] w-full object-cover max-sm:w-[100%]"
        />
      </div>

      {/* User Details */}
      <div className="UserDetails w-[50%] flex flex-col gap-3 p-4 bg-white max-sm:w-[100%]">
        <div>
          <h2 className="text-black text-xl font-semibold text-start">{selectedmemeber?.name}</h2>
          <p className="text-[#464646] text-md text-start">{selectedmemeber?.role}</p>
        </div>
        <div className="agent-Detail flex items-center gap-2">
          <CiPhone className="text-[#464646]" size={24} />
          <span className="text-[#464646] text-md">{selectedmemeber?.phone}</span>
        </div>
        <div className="agent-Detail flex items-center gap-2">
          <CiMail className="text-[#464646]" size={22} />
          <span className="text-[#464646] text-md">{selectedmemeber?.email}</span>
        </div>
        {/* <div className="agent-Detail flex items-center gap-2">
          <CiGlobe className="text-[#464646]" size={24} />
          <span className="text-[#464646] text-md">{`${selectedmemeber?.name?.toLowerCase().replace(/\s+/g, '')}.jd.ca`}</span>
        </div> */}
      </div>
    </div>


  {/* Buttons Section */}
  <div className="buttonSec flex mt-8 gap-4 mb-11 ">
        <button onClick={sendMail} className="flex items-center px-6 py-2 bg-[#C6A760] text-white font-medium rounded-xl shadow-md hover:bg-[#b2924c] transition">
        <IoIosMail className="hidden max-sm:inline"/>
        <span className="max-sm:hidden">Send Email</span>
        </button>
        <a href="tel:PHONE_NUM">

        <button className="flex items-center px-6 py-2 border border-[#C6A760] text-[#C6A760] font-medium rounded-xl shadow-md hover:bg-[#C6A760] hover:text-white transition" >

          <IoCall className="mr-2" size={24} /> Call
        </button>
        </a>
        <a href="https://wa.me/?text=Hello%20I'm%20interested%20in%20one%20of%20your%20listings." target='_blank'>
        <button className="flex items-center px-6 py-2 border border-[#C6A760] text-[#C6A760] font-medium rounded-xl shadow-md hover:bg-[#C6A760] hover:text-white transition">
          
          <FaWhatsapp className="mr-2" size={24} /> WhatsApp
        </button>
          </a>
      </div>
  {/* About Section */}
  <h1 className="text-xl font-bold text-start text-black">About Me</h1>

  {/* Contact Form */}
  <div className="contact-form w-full mt-12">
        <h2 className="text-1xl font-semibold mb-4 text-black text-start">
          Contact Me
        </h2>
        <div>
        <div className="grid lg:grid-cols-3 gap-4 mb-6">
          <div className="p-0 m-0">
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleOnChange}
              placeholder="Your Name *"
              className={` w-[100%] border p-2 rounded-md text-gray-700 focus:bg-gray-200 ${
                formErrors.name ? "border-Red-700" : ""
              }`}
            />
            {formErrors.name && (
              <p className="text-Red-700 text-sm text-start mt-2">
                Name is required *
              </p>
            )}
          </div>
          <div className="">
            <input
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleOnChange}
              placeholder="Your Email *"
              className={` w-[100%] border p-2 rounded-md text-gray-700 focus:bg-gray-200 ${
                formErrors.email ? "border-Red-700" : ""
              }`}
            />
            {formErrors.email && (
              <p className="text-Red-700 text-sm text-start mt-2">
                Email is required *
              </p>
            )}
          </div>
          <div>
            <input
              type="tel"
              name="phone"
              value={formValues.phone}
              onChange={handleOnChange}
              placeholder="Your Phone *"
              className={` w-[100%] border p-2 rounded-md text-gray-700 focus:bg-gray-200 ${
                formErrors.phone ? "border-Red-700" : ""
              }`}
            />
            {formErrors.phone && (
              <p className="text-Red-700 text-sm text-start mt-2">
                Phone number is required *
              </p>
            )}
          </div>
        </div>

        <textarea
          name="message"
          value={formValues.message}
          onChange={handleOnChange}
          placeholder="Your Message"
          className={` border p-2 rounded-md text-gray-700 w-full focus:bg-gray-200 ${
            formErrors.message ? "border-Red-700" : ""
          }`}
        ></textarea>
        {formErrors.message && (
          <p className="text-Red-700 text-sm text-start mb-6 mt-2">
            Message is required *
          </p>
        )}
        </div>
        {/* </div> */}
        <div className="text-start">
          <button
            onClick={handleOnSubmit}
            className="bg-[#D3B28C] text-white p-2 rounded-md hover:bg-[#C19F6B]"
          >
            Send Email
          </button>
        </div>
      </div>
</div>
  )
}

export default OurStoryProfile