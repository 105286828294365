import React, { useState } from "react";
import logo from "../assets/images/J_D-Logo_15_-WHITE-BG-Transparent-bg.webp";

import { IoLogoInstagram } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import AuthDialog from "./Login-SignIn";
import { useAuth } from '../hooks/AuthContext';
import { Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";


const Footer = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [initialForm, setInitialForm] = useState("login");
  const [isLogin, setIsLogin] = useState(true);
  const { isAuthenticated, logout } = useAuth();
  const switchForm = () => setIsLogin(!isLogin);
  const openDialog = (formType) => {
    setInitialForm(formType);
    setIsDialogOpen(true);
  };
  const closeDialog = () => setIsDialogOpen(false);

  // const scrollToTop = () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: 'smooth',
  //     });
  //   };

  return (
    <footer className="footer text-white  max-sm:p-0 pt-0">
      <div className="flex flex-col  ">
        <div className="flex flex-col lg:flex-row " >
        <div className="lg:w-[80%] w-full flex-wrap">
        <section
          className="lg:px-12 px-5  bg-Green-900"
          // style={{ backgroundColor: "rgb(14, 10, 36)" }}
        >
          <div className="footer-col   p-4  max-sm:p-0  max-md:flex-col md:flex-col lg:flex-row ">
            <div className="flex  items-center gap-10 max-sm:hidden">
              <h1 className="text-3xl text-white font-bold text-start ">
                Browse Active and Sold listings like never before Explore
              </h1>
              <ul className="flex gap-1 items-center mb-0">
                <li>
                { isAuthenticated ? (
                <button
                className={`p-3 mb-2 rounded-full w-24 xl:w-20 xl:p-2 border border-white hover:bg-black hover:text-white`}
                onClick={() => openDialog('login')}
              >
              Sign Out
              </button>
              ) : (
                <button
                className={`p-3 mb-2 rounded-full w-24 xl:w-20 xl:p-2 border border-white hover:bg-black hover:text-white`}
                onClick={() => openDialog('login')}
              >
                Sign in
              </button>
              )}
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section
          // style={{ backgroundColor: "rgb(14, 10, 36)" }}
          className="px-16 max-sm:px-5 max-sm:flex max-sm:justify-center pb-5  bg-Green-900"
        >
          <div className="grid grid-cols-4 gap-x-14  max-sm:grid-cols-2">
            <div className="mt-4  text-start max-sm:text-center ">
              <div className="flex gap-3 items-center mb-3  max-sm:justify-center ">
                {/* <FaLocationDot size={28} style={{color:"#464646"}}/> */}
                <h2 className="text-xl font-bold "> Toronto</h2>
              </div>
              <li className=" list-none max-sm:text-[13px]">
                477 MOUNT PLEASANT ROAD
              </li>
              <li className="list-none max-sm:text-[13px]">(416) 489-2121</li>
            </div>
            <div className="mt-4 text-start max-sm:text-center ">
              <div className="flex gap-3 items-center mb-3  max-sm:justify-center ">
                {/* <FaLocationDot size={28} style={{color:"#464646"}}/> */}
                <h2 className="text-xl font-bold">Oakville</h2>
              </div>
              <li className="list-none max-sm:text-[13px]">
                326 LAKESHORE ROAD EAST
              </li>
              <li className="list-none max-sm:text-[13px]">(905) 845-1223</li>
            </div>
            <div className="mt-4  text-start  max-sm:text-center ">
              <div className="flex gap-3 items-center mb-3  max-sm:justify-center">
                {/* <FaLocationDot size={28} style={{color:"#464646"}}/> */}
                <h2 className="text-xl font-bold  ">Muskoka</h2>
              </div>
              <li className="list-none max-sm:text-[13px]">
                118 MEDORA STREET
              </li>
              <li className="list-none max-sm:text-[13px]">(705) 765-6855</li>
            </div>
            <div className="mt-4 text-start max-sm:text-center ">
              <div className="flex gap-3 items-center mb-3  max-sm:justify-center ">
                {/* <FaLocationDot size={28} style={{color:"#464646"}}/> */}
                <h2 className="text-xl font-bold  ">Kawarthas</h2>
              </div>
              <li className="list-none max-sm:text-[13px]">
                3133-1 MUSKOKA ROAD
              </li>
              <li className="list-none max-sm:text-[13px]">(705) 762-3410</li>
            </div>
            <div className="mt-4 text-start max-sm:text-center "></div>
          </div>
        </section>
        </div>
          <div className="lg:w-[20%] w-full  lg:pr-5  bg-Green-900">
             <div className='ml-3 max-sm:order-1 max-sm:hidden flex justify-center'>
                  
                <img src={logo} alt="logo" width={220} height={230}></img>
                   
                </div>
                

            <div className="flex flex-row gap-4 max-sm:order max-sm:gap-12 mb-3  max-sm:text-center ml-3 justify-center pt-2 lg:pt-0 pr-4">
                    <a
                      className="hover:text-gold-400 transition-colors duration-300"
                      href="https://www.instagram.com/johnstonanddaniel/"
                      target="_blank"
                    >
                      <IoLogoInstagram size={24} />
                    </a>

                    <a
                      className="hover:text-gold-400 transition-colors duration-300"
                      href="https://www.facebook.com/JohnstonandDaniel/"
                      target="_blank"
                    >
                      <FaFacebookF size={24} />
                    </a>
                    <a
                      className="hover:text-gold-400 transition-colors duration-300"
                      href="https://x.com/i/flow/login?redirect_after_login=%2Fjohnstondaniel"
                      target="_blank"
                    >
                      <FaXTwitter size={24} />
                    </a>
                    <a
                      className="hover:text-gold-400 transition-colors duration-300"
                      href="https://www.linkedin.com/company/johnston-and-daniel/"
                      target="_blank"
                    >
                      <FaLinkedin size={24} />
                    </a>
                  </div>

            <li className="list-none flex justify-center gap-2 mb-3 ml-3 pr-4"><span>(416) 489-2121</span></li>
            <li className="list-none flex justify-center mb-3  gap-2 lg:ml-3 lg:pr-4 w-[100%]"><span>info@johnstonanddaniel.com</span></li>
            <div className="lg:hidden"> 
              { isAuthenticated ? (
                <button
                className={`p-3 mb-2 rounded-full w-24 xl:w-20 xl:p-2 border border-[#F2CC2F] hover:bg-black hover:text-white`}
                onClick={() => openDialog('login')}
              >
              Sign Out
              </button>
              ) : (
                <button
                className={`p-3 mb-2 rounded-full w-24 xl:w-20 xl:p-2 border border-[#F2CC2F] hover:bg-black hover:text-white`}
                onClick={() => openDialog('login')}
              >
                Sign in
              </button>
              )}
            </div>
            


          </div>
        </div>

        <section
          className="lg:px-16  border-t-2  bg-Green-900 "
        >
          <div className="flex flex-wrap lg:justify-between justify-center items-center">
            <div className="flex lg:gap-10 gap-3 text-sm p-4 px-0 lg:flex-1">
              <Link>
                {" "}
                <li className="list-none cursor-pointer hover:text-gold-400 transition-colors duration-300">
                  Home
                </li>
              </Link>
              <Link to="/buy">
                <li className="list-none cursor-pointer hover:text-gold-400 transition-colors duration-300">
                  Buy
                </li>
              </Link>
              <Link to="/sell">
                {" "}
                <li className="list-none cursor-pointer hover:text-gold-400 transition-colors duration-300">
                  Sell
                </li>
              </Link>
              <Link to="/ourteam">
                {" "}
                <li className="list-none cursor-pointer hover:text-gold-400 transition-colors duration-300">
                  Our Team
                </li>
              </Link>
              <Link to="/ourstory">
                {" "}
                <li className="list-none cursor-pointer hover:text-gold-400 transition-colors duration-300">
                  Our story
                </li>
              </Link>
              <Link to="/blog">
                {" "}
                <li className="list-none cursor-pointer hover:text-gold-400 transition-colors duration-300">
                  Blog
                </li>
              </Link>
              <Link to="/contact">
                {" "}
                <li className="list-none cursor-pointer hover:text-gold-400 transition-colors duration-300">
                  Contact
                </li>
              </Link>
            </div>
           
            <div className="  gap-6  max-sm:text-center flex p-4 px-0">
              <Link to="/privacy-policy">
                <li className="hover:text-gold-400 transition-colors duration-300 list-none cursor-pointer">
                  Privacy Policy
                </li>
              </Link>
              <Link to="/Accessibility">
                <li className="hover:text-gold-400 transition-colors duration-300 list-none cursor-pointer">
                  Accessibility
                </li>
              </Link>
            </div>
           
          </div>
        </section>
        <section className="px-16 max-sm:px-3 ">
          <div className="flex flex-col max-sm:order-2  text-black pt-5 mt-2 max-sm:px-0">
            <div className="flex flex-row items-center justify-between max-sm:flex-col max-sm:gap-4"></div>
            <div>
              <p
                className="paraghraph text-start text-[14px]"
                style={{ fontFamily: '"Roboto","sans-serif"' }}
              >
                © 2020 Johnston &amp; Daniel, A Division of Royal LePage Real
                Estate Services Ltd., Brokerage. All rights reserved. Not
                intended to solicit buyers or sellers, landlords or tenants
                currently under contract. The trademarks REALTOR®, REALTORS® and
                the REALTOR® logo are controlled by The Canadian Real Estate
                Association (CREA) and identify real estate professionals who
                are members of CREA. The trademarks MLS®, Multiple Listing
                Service® and the associated logos are owned by CREA and identify
                the quality of services provided by real estate professionals
                who are members of CREA. REALTOR® contact information provided
                to facilitate inquiries from consumers interested in Real Estate
                services.&nbsp;
              </p>
              <p className=" paraghraph mt-5 text-start text-[14px] font-roboto">
                *Johnston &amp; Daniel Rushbrooke Realty Ltd. | Independently
                owned and operated. Any services or products provided by
                independently owned and operated franchisees are not provided
                by, affiliated with or related to Johnston &amp; Daniel, A
                Division of Royal LePage Real Estate Services Ltd., Brokerage,
                nor any of its affiliated companies.
              </p>
            </div>
          </div>
        </section>
      </div>
      <AuthDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        isLogin={isLogin}
        switchForm={switchForm}
        initialForm={initialForm}
      />
    </footer>
  );
};

export default Footer;
