import BlogImg1 from "../assets/images/blogImage-banner.webp";
import BlogImg2 from "../assets/images/QC-Montreal-Skyline_hero.webp";
import BlogImg3 from "../assets/images/blogImage-4.webp";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import img1 from "../assets/images/9.6-2-1-1-525x328.webp";
import img2 from "../assets/images/10-4-1-1-1.webp";
import img3 from "../assets/images/j-1-1-1-525x328.webp";
import img4 from "../assets/images/2.6-6-1-1-525x328.webp";
import img5 from "../assets/images/5.3-3-1-1-525x328.webp";
import img6 from "../assets/images/4-1-1-1-525x328.webp";
import img7 from "../assets/images/sell-1-1-1-525x328.webp";
import img8 from "../assets/images/x-4-1-1-525x328.webp";
// import img9 from "../assets/images/vecteezy_happy-asian-couple-client-tenant-buy-home-realtor-hand_25133377.jpg";

const BlogContent = () => {
    const navigate = useNavigate(); 
    const { key } = useParams();
    const location = useLocation();
    const { selectedblog} = location.state || {}; 


    const blogArray = [
        {
          key: "Best areas for families with kids",
          img: img1,
          mainHead: "Best areas for families with kids",
          date: "March 4, 2016",
          content:
            "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5  ",
          id: 1,
        },
        {
          key: "The top neighborhoods",
          img: img2,
          mainHead: "The top neighborhoods",
          date: "March 4, 2016",
          content:
            "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
    
          id: 2,
        },
        {
          key: "Buyer's Guide",
          img: img3,
          mainHead: "Buyer's Guide",
          date: "May 27, 2016",
          content:
            "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5  ",
          id: 3,
        },
        {
          key: "Seller's Guide",
          img: img4,
          mainHead: "Seller's Guide",
          date: "March 4, 2016",
          content:
            "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
          id: 4,
        },
        {
          key: "Reasons to sell before New Year",
          img: img5,
          mainHead: "Reasons to sell before New Year",
          date: "May 27, 2016",
          content:
            "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
          id: 5,
        },
        {
          key: "What to look for in an agent",
          img: img8,
          mainHead: "What to look for in an agent",
          date: "May 27, 2016",
          content:
            "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
          id: 6,
        },
        {
          key: "The best of Torront’s vibrant cities from coast to coast",
          img: img7,
          mainHead: "The best of Torront’s vibrant cities from coast to coast",
          date: "May 27, 2016",
          content:
            "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5  ",
          id: 7,
        },
        {
          key: "The Top 10 Attraction in Monteral",
          img: img6,
          mainHead: "The Top 10 Attraction in Monteral",
          date: "May 27, 2016",
          content:
            "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
          id: 8,
        },
      ];

      
      const handleNavigate = (key) => {
        const selectedBlog = blogArray.find((blog) => blog.key === key);
      
        navigate(`/neighborhoods/${key}`)
      };
   
    return (
        <div className="p-10 flex flex-col w-full gap-5 mt-4 max-sm:p-2 max-sm:mt-20">
            <div className="flex flex-col mb-4">
                <h1 className="text-[45px] max-sm:text-2xl">Recent Blog Posts</h1>
                <p className="text-lg text-gray-500">View featured blog posts from our local experts.</p>
            </div>
           
            <div className="relative group cursor-pointer" onClick={()=> handleNavigate(`The best of Torront’s vibrant cities from coast to coast`)}>
                <img src={BlogImg1} alt='blog' className='w-full h-[400px] object-cover opacity-80' />
                <div className="absolute inset-0 bg-black opacity-40"></div>
                <div className="absolute top-[72%] p-3 inset-0 max-sm:top-[52%]">
                        <p className="text-3xl font-bold text-white text-center">The best of Torront’s vibrant cities from coast to coast</p>
                        <p className=" text-white text-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">To receive the best vibe in the city, we have created a blog for you.</p>
                    </div>
            </div>
          
            
            <div className="flex flex-row gap-5 max-sm:flex-col">
            {/* <Link to={`/neighborhoods/2`}> */}
                <div className="basis-1/2 relative group cursor-pointer "  onClick={() => handleNavigate(`Best areas for families with kids`)} >
                    <img src={BlogImg2} alt='blog' className='object-cover h-[400px] w-full opacity-80' />
                <div className="absolute inset-0 bg-black opacity-40"></div>
                    <div className="absolute top-[72%] p-3 inset-0 text-start max-sm:top-[57%]">
                        <p className="text-3xl font-bold text-white">Best Areas for family and kids</p>
                        <p className=" text-white text-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">To receive the best vibe in the city, we have created a blog for you.</p>
                    </div>
                </div>
                {/* </Link> */}
                <div className="basis-1/2 relative group cursor-pointer " onClick={() => handleNavigate(`The Top 10 Attraction in Monteral`)}>
                    <img src={BlogImg3} alt='blog' className='object-cover h-[400px] w-full opacity-80 '  />
                <div className="absolute inset-0 bg-black opacity-40"></div>
                    <div className="absolute top-[72%] p-3 inset-0 text-start max-sm:top-[57%]">
                        <p className="text-3xl font-bold text-white">The Top 10 Attraction in Monteral</p>
                        <p className=" text-white text-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">To receive the best vibe in the city, we have created a blog for you.</p>
                    </div>
                </div>
            </div>
           
            <div className="mt-10">
                <Link to='/blog'>
                <button className="p-3 border border-gray-200 bg-Green-400 text-white w-32
                hover:bg-white hover:border-Green-900 hover:text-black">Load More</button>
                </Link>
            </div>
        </div>
    );
}

export default BlogContent;