import image1 from '../assets/images/toronto.webp';
import image2 from '../assets/images/Muskoka4.webp';
import image3 from '../assets/images/oakville1.webp';
import image4 from '../assets/images/kawarthas3.webp'
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const FeatureNeighbour = () => {
  const navigate = useNavigate();

  const images = [
    {
      image: image1,
      name: "Toronto",
      key:"Toronto"
    },
    {
      image: image2,
      name: "Muskoka",
      key:"Muskoka"
    },
    {
      image: image3,
      name: "Oakville",
      key:"Oakville"
    },
    {
      image: image4,
      name: "Kawarthas",
      key: "Kawartha Lakes"
    },
  ];
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.5,
      },
    },
  };

  const handlesubmit = (key) => {
    const Selectitem = images?.find((place) => place.key === key);
    if (Selectitem) {
      navigate(`/buy/${key}`, { state: { images } });
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: 100 }, // Start off the screen on the right
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }, // Smoother transition
  };
  const ref = useRef(null);
  const inView = useInView(ref, { once: false });

  return (
    <motion.div
      ref={ref}
      className="flex p-5 h-[600px] gap-7 mt-4 overflow-hidden max-sm:flex-col max-sm:p-2 max-sm:h-auto max-lg:flex-col max-lg:h-auto"
      variants={containerVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
    >
      <motion.div
        className="basis-1/5 p-5 text-start flex flex-col justify-start gap-3"
        variants={itemVariants}
      >
        <h1 className="text-2xl max-sm:text-center max-sm:font-playfair">
          Featured Neighbourhoods
        </h1>
        <p
          style={{
            color: "#464646",
            fontFamily: '"Open Sans", "Sans-serif"',
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "1.8em",
          }}
          className=" max-sm:text-center"
        >
          In the vibrant city of Toronto, Ontario. Our Realtors are renowned
          among residents, property developers, local businesses, and
          professionals in the real estate industry for their remarkable skills,
          dynamic approach, and tireless commitment to facilitating transactions
          for buyers and sellers.
        </p>
      </motion.div>

      {images.map((image, index) => (
        <motion.div
          key={index}
          className="basis-1/5  cursor-pointer  relative "
          variants={itemVariants}
          onClick={() => handlesubmit(image.key)}
        >
          <img
            src={image.image}
            alt={image.name}
            className="h-full opacity-80 hover:opacity-100 transition-opacity duration-300 max-sm:w-full max-lg:w-full   transform hover:scale-105"
            loading="lazy"
            style={{
              transition:
                "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
            }}
          />
          {/* <div  style={{
              transition:
                "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
            }}  className="absolute inset-0 bg-black opacity-40 "></div> */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
            <h1 className="text-3xl font-semibold">{image.name}</h1>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default FeatureNeighbour;
