import { Box, Button, Modal } from "@mui/material";
// import emailjs from "emailjs-com";
import React, { useEffect, useRef, useState } from "react";
import {
  FaEnvelope, FaFacebookMessenger, FaHeart, FaInstagram, FaLinkedin, FaPinterest,
  FaReddit, FaTelegram, FaTwitter, FaWhatsapp
} from "react-icons/fa";
import { GiHomeGarage } from "react-icons/gi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoBedOutline, IoHammerOutline, IoHomeOutline, IoPrintOutline, IoShareOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Tooltip } from "react-tooltip";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../App.css";
import AuthDialog from "../components/Login-SignIn";
import SinglePropertiesMap from "../components/SinglePropertiesMap";
import RequestTourInfo from "../components/dialog-components/requesttourInfo";
import LoaderCard from "../components/loader";
import DialogModal from "../components/photosDialog";
import { getListings, getSimilarListings, getSingleListing } from "../service";

const PropertyView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indexValue, setIndexValue] = useState(0);
  const [singleListing, setSingleListing] = useState({});
  const [loading, setLoading] = useState(true);
  const [coordinates, setCoordinates] = useState({});
  const [isRequestTourOpen, setIsRequestTourOpen] = useState(false);
  const [title, setTitle] = useState("tour");
  const [featureListings, setFeatureListings] = useState([]);
  const [similarListings, setSimilarListings] = useState([]);
  const [similarlistingLoading, setSimilarListingLoading] = useState(false);
  const [isPrintVisible, setIsPrintVisible] = useState(false);


  const [modal, setModal] = useState(false);
 

  const [initialForm, setInitialForm] = useState("login");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  const closeDialog = () => setIsDialogOpen(false);
  const switchForm = () => setIsLogin(!isLogin);
  const openDialog = (formType) => {
    setInitialForm(formType);
    setIsDialogOpen(true);
  };
  const copyUrl = window.location.href;
 

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(copyUrl);
      alert("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
      alert("Failed to copy the link.");
    }
  };

  const handleShare = () => {
    setModal(true);
  };


  useEffect(() => {
    getSingleListingValue(id);
    getSimilarItems(id);
    getFeatureListings();
  }, [id]);

  const getSingleListingValue = async (id) => {
    setLoading(true);
    try {
      const response = await getSingleListing(id);
      setSingleListing(response.data.listing);
      const coordinates = {
        lat: response.data.listing.map.latitude,
        lng: response.data.listing.map.longitude,
      };
      setCoordinates(coordinates);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getFeatureListings = async () => {
    const filterQuery =
      "&propertyType=Detached&minBeds=5&minBaths=5&minPrice=5000000&maxPrice=10000000";
    try {
      const result = await getListings(1, 4, filterQuery);
      setFeatureListings(result.data.listings);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSimilarItems = async (id) => {
    try {
      setSimilarListingLoading(true);
      const result = await getSimilarListings(id);
      setSimilarListings(result.data.listing);
    } catch (error) {
      console.error(error);
    } finally {
      setSimilarListingLoading(false);
    }
  };

  const handleShowAllPhotos = (index) => {

    setIndexValue(index);
    setIsModalOpen(true);
  };
  const handleRequestTour = (val) => {
    if (val === "tour") {
      setTitle("tour");
    } else {
      setTitle("info");
    }
    setIsRequestTourOpen(true);
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(price);
  };

  const closeModalopen = () => {
    setModal(false);
  };

  const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   contentRef: componentRef,
  //   documentTitle: "Jonston & Danial",
   
  // });
  // const hendle = ()=>{
  //   setIsPrintVisible(true); 
  //   handlePrint()
  // }
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "Jonston & Danial",
    onBeforeGetContent: () => {
      setIsPrintVisible(true); 
      return Promise.resolve(); 
    },
    onAfterPrint: () => {
      setIsPrintVisible(false); 
    },
    // onBeforePrint :()=>{
    //   setIsPrintVisible(true); 
    // }
  });

  const shareLinks = {
    whatsapp: `https://wa.me/?text=${encodeURIComponent(copyUrl)}`,
    messenger: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      copyUrl
    )}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      copyUrl
    )}&text=Check%20this%20out!`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      copyUrl
    )}`,
    telegram: `https://t.me/share/url?url=${encodeURIComponent(
      copyUrl
    )}&text=Check%20this%20out!`,
    pinterest: `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
      copyUrl
    )}&description=Check%20this%20out!`,
    reddit: `https://www.reddit.com/submit?url=${encodeURIComponent(
      copyUrl
    )}&title=Check%20this%20out!`,
    instagram: `https://www.instagram.com/`,
  };
  return (
    <>
      <div>
        {loading ? (
          Array(5)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                className={`${
                  index === 0 ? "col-span-2 row-span-2" : "col-span-1"
                } 
                       bg-gray-300 animate-pulse rounded-lg h-48 w-full`}
              ></div>
            ))
        ) : (
          <div className="px-14 lg:pt-24 pt-20  max-sm:px-5 md:px-5">
            <div className="flex gap-3 justify-end p-5">
              <div className="flex items-center gap-3 cursor-pointer">
                <div
                  className="p-2 border rounded-full border-gray-300 "
                  onClick={() => openDialog("login")}
                >
                  <FaHeart />
                </div>
                <span>Save</span>
              </div>
              {isDialogOpen && (
                <AuthDialog
                  isOpen={isDialogOpen}
                  onClose={closeDialog}
                  isLogin={isLogin}
                  switchForm={switchForm}
                  initialForm={initialForm}
                />
              )}
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={handleShare}
              >
                <div className="p-2 border rounded-full border-gray-300">
                  <IoShareOutline />
                </div>
                <span>Share</span>
              </div>
              <div className="flex items-center gap-3 cursor-pointer">
                <div
                  className="p-2 border rounded-full border-gray-300"
                  onClick={handlePrint}
                >
                  <IoPrintOutline />
                </div>
                <span>Print</span>
              </div>
            </div>
          
            <Modal
              open={modal}
              onClose={closeModalopen}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  // bgcolor: "background.paper",
                  boxShadow: 24,
                  borderRadius: 25,
                  outline: "none",
                  
                }}
                className='lg:w-[400px] md:w-[400px] w-[370px] '
              >
                <div className="login_content   rounded-3xl  p-5 bg-gray-900">
                  <h2 className="text-2xl font-bold  text-white text-start mb-6">
                    Share
                  </h2>

                  <div className="flex space-x-4 py-3 mb-4">
                    <a
                      href={shareLinks.messenger}
                      target="_blank"
                      rel="noopener noreferrer"
                     
                      className="group relative"
                    >
                      <button
                        // onClick={handleMessengerShare}
                        className="flex w-full  items-center justify-center p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
                      >
                        <FaFacebookMessenger size={40}  />
                      </button>
                      <span className="absolute hidden group-hover:block  text-white text-xs font-bold p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                      Messenger
                      </span>
                    </a>
                    <a
                      href={shareLinks.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                       
                      className="group relative"
                      
                    >
                      <button className="flex items-center justify-center p-2 bg-blue-400 text-white rounded-full hover:bg-blue-500">

                      <FaTwitter size={40} />
                      </button>
                      <span className="absolute hidden group-hover:block font-bold  text-white text-xs p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                      Twitter
                      </span>
                    </a>
                    <a
                      href={shareLinks.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                     
                      className="group relative"
                    >
                      <button className="flex items-center justify-center p-2 bg-blue-700 text-white rounded-full hover:bg-blue-800"
                      >

                      <FaLinkedin size={40} />
                      </button>
                      <span className="absolute hidden group-hover:block font-bold  text-white text-xs p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                      Linkedin
                      </span>
                    </a>
                    <a
                      href={shareLinks.reddit}
                      target="_blank"
                      rel="noopener noreferrer"
                      
                      className="group relative"
                      
                    >
                      <button className="flex items-center justify-center p-2 bg-orange-500 text-white rounded-full hover:bg-orange-600">

                      <FaReddit size={40} />
                      </button>
                      <span className="absolute hidden group-hover:block font-bold  text-white text-xs p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                      Reddit
                      </span>
                    </a>
                    <a
                      href={shareLinks.pinterest}
                      target="_blank"
                     
                      
                       rel="noopener noreferrer"
                      className="group relative"
                    >
                      <button className="flex items-center justify-center p-2 bg-red-500 text-white rounded-full hover:bg-red-600">

                      <FaPinterest size={40} />
                      </button>
                      <span className="absolute hidden group-hover:block  font-bold text-white text-xs p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                      Pinterest
                      </span>
                    </a>
                  </div>
                  <div className="mb-6">
                    <div className="flex items-center bg-gray-700 rounded-full p-2">
                      <input
                        value={copyUrl}
                        readOnly
                        className="w-full pl-3 text-sm bg-transparent text-white focus:outline-none"
                 
                      />
                      <button
                        onClick={copyLink}
                        className="px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-full hover:bg-blue-600"
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                  <div className="flex space-x-4 py-3">
                    <a
                      href={shareLinks.whatsapp}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group relative"
                    >
                      <button className="flex items-center justify-center p-2 bg-Green-300 text-white rounded-full hover:bg-green-600">
                        <FaWhatsapp size={40} />
                      </button>
                      <span className="absolute hidden group-hover:block font-bold  text-white text-xs p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                        WhatsApp
                      </span>
                    </a>

                    <a
                      href={shareLinks.telegram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group relative"
                    >
                      <button className="flex items-center justify-center p-2 bg-blue-700 text-white rounded-full ">
                        <FaTelegram size={40} />
                      </button>
                      <span className="absolute hidden group-hover:block font-bold  text-white text-xs p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                        Telegram
                      </span>
                    </a>
                    <a
                      href={shareLinks.messenger}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group relative"
                    >
                      <button
                        // onClick={handleMessengerShare}
                        className="flex items-center justify-center p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
                      >
                        <FaFacebookMessenger size={40} />
                      </button>
                      <span className="absolute hidden group-hover:block font-bold  text-white text-xs p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                        Messenger
                      </span>
                    </a>
                    <a
                      href={shareLinks.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group relative"
                    >
                      <button
                        className="flex items-center justify-center p-2  text-white rounded-full "
                        style={{
                          background:
                            "linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)",
                        }}
                      >
                        <FaInstagram size={40} />
                      </button>
                      <span className="absolute hidden group-hover:block font-bold  text-white text-xs p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                        Instagram
                      </span>
                    </a>
                    <a
                      href={`mailto:?subject=Check%20this%20out!&body=${encodeURIComponent(
                        copyUrl
                      )}`}
                      className="group relative"
                      rel="noopener noreferrer"
                    >
                      <button         className="flex items-center justify-center p-2 bg-[#DB4437] text-white rounded-full hover:bg-[#C1351D] ">

                      <FaEnvelope size={40} />
                      </button>
                      <span className="absolute hidden group-hover:block font-bold  text-white text-xs p-1 rounded-md  mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                        Mail
                      </span>
                    </a>
                  </div>

                  <Box>
                    <Button
                      className="p-2 font-semibold text-white bg-Stone-600 rounded-full hover:bg-Stone-700"
                      onClick={closeModalopen}
                    >
                      Close
                    </Button>
                  </Box>
                </div>
              </Box>
            </Modal>

       
          <div  ref={componentRef} style={{ width: "100%",padding:"0px 20px" }}>
            {/* <div className=" grid grid-cols-1 sm:grid-cols-4 gap-4 h-[600px]">
              {singleListing.images &&
                // singleListing.images.slice(0, 5).map((image, index) => (
                      singleListing.images.slice(0, isPrintVisible ? 1 : 5).map((image, index) => (

                  <div
                    key={index}
                    className={`${
                      index === 0
                        ? "col-span-1 sm:col-span-2 sm:row-span-2"
                        : "col-span-1"
                    } relative overflow-hidden rounded-lg cursor-pointer`}
                    onClick={() => handleShowAllPhotos(index)}
                  >
                    <img
                      src={image}
                      alt={`image-${index}`}
                      className="object-cover w-full h-full"
                    />
                    {index === 4 && (
                      <div className="absolute inset-0 flex items-end justify-end p-5">
                        <button
                          onClick={() => handleShowAllPhotos(0)}
                          className="bg-black bg-opacity-50 text-white text-lg font-bold rounded-lg px-4 py-2"
                        >
                          Show All Photos
                        </button>
                      </div>
                    )}
                  </div>
                ))}
            </div> */}
            <div className="imageGrid grid grid-cols-1 sm:grid-cols-4 gap-4 h-[600px]">
  {singleListing.images &&
    singleListing.images.slice(0, isPrintVisible ? 0 : 5).map((image, index) => (
      <div
        key={index}
        className={`${
          index === 0
            ? "col-span-1 sm:col-span-2 sm:row-span-2"
            : "col-span-1"
        } relative overflow-hidden rounded-lg cursor-pointer`}
        onClick={() => handleShowAllPhotos(index)}
      >
        <img
          src={image}
          alt={`image-${index}`}
          className="object-cover w-full h-full"
        />
        {index === 4 && (
          <div className="absolute inset-0 flex items-end justify-end p-5">
            <button
              onClick={() => handleShowAllPhotos(0)}
              className="bg-black bg-opacity-50 text-white text-lg font-bold rounded-lg px-4 py-2"
            >
              Show All Photos
            </button>
          </div>
        )}
      </div>
    ))}
</div>


            
           

            <DialogModal
              isOpen={isModalOpen}
              title={"imageDialog"}
              onClose={closeModal}
              value={singleListing.images}
              index={indexValue}
            />

            <div className=" flex flex-row mt-10  font-roboto gap-10 max-sm:flex-col md:flex-col lg:flex-row">
            
              <div className="w-[77%] max-sm:w-[100%] md:w-[100%] lg:w-[70%]">
                <div className="flex flex-col w-full">
                  {/* <div><h1 className="text-start text-3xl font-bold">{singleListing.office.brokerageName}</h1></div> */}
                  <div className="flex justify-between max-sm:flex-col">
                    <div className="flex gap-3 items-center text-2xl ">
                      <span className="">
                        <HiOutlineLocationMarker />
                      </span>
                      <h2
                  className='text-2xl font-semibold max-sm:text-start max-sm:mt-3'
                  data-tooltip-id='my-tooltip'
                  data-tooltip-content={`${singleListing.address.streetNumber} ${singleListing.address.streetName}, ${singleListing.address.city}, ${singleListing.address.zip}`}
                >{`${singleListing.address.streetNumber} ${singleListing.address.streetName}, ${singleListing.address.city}, ${singleListing.address.zip}`}</h2>
                    </div>
                    <h1 className="text-2xl font-semibold max-sm:text-start max-sm:mt-3">
                      {singleListing.listPrice
                        ? formatPrice(singleListing.listPrice)
                        : ""}
                    </h1>
                  </div>
                </div>
                <div className="w-full grid-cols-3 grid gap-3 mt-8 max-sm:grid-cols-2">
                  <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center">
                    <span>
                      <IoHomeOutline size={22} />
                    </span>
                    <p>
                      {singleListing?.details?.propertyType
                        ? singleListing.details.propertyType
                        : ""}
                    </p>
                  </div>
                  <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center  max-sm:justify-end">
                    <span>
                      <IoHammerOutline size={22} />
                    </span>
                    <p>
                      Build Year{" "}
                      {singleListing.details.yearBuilt
                        ? singleListing.details.yearBuilt
                        : "-"}
                    </p>
                  </div>
                  {/* <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center">
                    <span>
                      <BiArea size={22} />
                    </span>
                    <p>
                      {singleListing.lot
                        ? `${singleListing.lot.depth} X ${singleListing.lot.width}`
                        : `0 X 0`}{" "}
                      Ft Lot
                    </p>
                  </div> */}
                  {/* {singleListing.details.numBedrooms > 0 && ( */}
                    <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center  max-sm:justify-end">
                      <span>
                        <IoBedOutline size={22} />
                      </span>
                      <p>
                        {singleListing.details?.numBedrooms
                          ? singleListing.details.numBedrooms
                          : "0"}{" "}
                        Bedrooms
                      </p>
                    </div>
                  {/* )} */}
                  {/* {singleListing.details.numBathrooms > 0 && ( */}
                    <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center ">
                      <span>
                        <LiaBathSolid size={22} />
                      </span>
                      <p>
                        {singleListing.details?.numBathrooms
                          ? singleListing.details.numBathrooms
                          : "0"}{" "}
                        Bathrooms
                      </p>
                    </div>
                  {/* )} */}
                  {singleListing.details?.numGarageSpaces > 0 && (

                  <div className="flex gap-3 p-3 bg-gray-100 rounded-lg items-center max-sm:justify-end">
                    <span>
                      <GiHomeGarage size={22} />
                    </span>
                    <p>
                    {singleListing.details?.numGarageSpaces
    ? Math.trunc(singleListing.details.numGarageSpaces) 
    : "0"}{" "}
                      Garage
                    </p>
                  </div>
                  )

                  }
                </div>
                <div className="mt-10 text-start">
                  <h1 className="text-xl font-semibold">Description</h1>
                  <p className="text-gray-500 mt-6 text-base leading-8">
                    {singleListing.details?.description}
                  </p>
                  {/* <p className="text-gray-500 mt-6 text-base leading-8">Enchanting three bedroom, three bath home with spacious one bedroom, one bath cabana, in-laws quarters.
                 Charming living area features fireplace and fabulous art deco details. Formal dining room.
               </p>
               <p className="text-gray-500 mt-4 text-base leading-8">Remodeled kitchen with granite countertops, white cabinetry and stainless appliances. Lovely master bedroom has updated bath, beautiful view of the pool. Guest bedrooms have walk-in, cedar closets. Delightful backyard; majestic oaks surround the free form pool and expansive patio, wet bar and grill.</p> */}
                </div>

                <div className="mt-10 text-start">
                  <h1 className="text-xl font-semibold">Property Details</h1>
                  <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12 max-sm:grid-cols-1">
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">MLS No</p>
                      <p className="text-gray-500">{singleListing.mlsNumber}</p>
                    </div>

                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Property Style</p>
                      <p className="text-gray-500">
                        {singleListing.details.style
                          ? singleListing.details.style
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">List Price</p>
                      <p className="text-gray-500">
                        {singleListing.listPrice
                          ? `$ ${singleListing.listPrice}`
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Bedrooms</p>
                      <p className="text-gray-500">
                        {singleListing.details?.numBathrooms
                          ? singleListing.details?.numBathrooms
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Parking</p>
                      <p className="text-gray-500">
                        {singleListing.details?.numBathrooms
                          ? singleListing.details?.numBathrooms
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Garage</p>
                      <p className="text-gray-500">
                        {singleListing.details?.garage
                          ? singleListing.details.garage
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Build year</p>
                      <p className="text-gray-500">
                        {singleListing.details.yearBuilt
                          ? singleListing.details.yearBuilt
                          : "-"}{" "}
                      </p>
                    </div>
                    {singleListing.details.style === "Apartment" ? (
                      <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                        <p className="font-medium">Sqft</p>
                        <p className="text-gray-500">
                          {singleListing.details.sqft
                            ? singleListing.details.sqft
                            : `-`}{" "}
                          Sqft
                        </p>
                      </div>
                    ) : (
                      <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                        <p className="font-medium">Lot Size</p>
                        <p className="text-gray-500">
                          {singleListing.lot
                            ? `${singleListing.lot.depth} X ${singleListing.lot.width}`
                            : `-`}{" "}
                          Ft Lot
                        </p>
                      </div>
                    )}

                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Sewer</p>
                      <p className="text-gray-500">
                        {singleListing.details?.sewer
                          ? singleListing.details?.sewer
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Construction</p>
                      <p className="text-gray-500">
                        {singleListing.details.exteriorConstruction1
                          ? singleListing.details.exteriorConstruction1
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 text-start">
                  <h1 className="text-xl font-semibold">Address</h1>
                  <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12 max-sm:grid-cols-1">
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Street</p>
                      <p className="text-gray-500">
                        {singleListing.address?.streetName
                          ? singleListing.address?.streetName
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Zip/Postal Code</p>
                      <p className="text-gray-500">
                        {singleListing.address?.zip
                          ? singleListing.address?.zip
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">City</p>
                      <p className="text-gray-500">
                        {singleListing.address?.city
                          ? singleListing.address?.city
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Area</p>
                      <p className="text-gray-500">
                        {singleListing.address?.area
                          ? singleListing.address?.area
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">District</p>
                      <p className="text-gray-500">
                        {singleListing.address?.district
                          ? singleListing.address?.district
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">State</p>
                      <p className="text-gray-500">
                        {singleListing.address?.state
                          ? singleListing.address?.state
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 text-start">
                  <h1 className="text-xl font-semibold"> Listing Details</h1>
                  <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12 max-sm:grid-cols-1 md:grid-cols-1 ">
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Brokerage</p>
                      <p className="text-black font-normal">
                        {singleListing.office?.brokerageName}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Data Source</p>
                      <p className="text-black font-normal">-</p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Listed On</p>
                      <p className="text-black font-normal">
                        {formatDate(singleListing.listDate)}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Updated On</p>
                      <p className="text-black font-normal">
                        {formatDate(singleListing.timestamps?.listingUpdated)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 text-start">
                  <h1 className="text-xl font-semibold">Interior Details</h1>
                  <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12 max-sm:grid-cols-1">
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Bedrooms</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.numBathrooms
                          ? singleListing.details?.numBathrooms
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Bathrooms</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.numBathrooms
                          ? singleListing.details?.numBathrooms
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Rooms</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.numRooms
                          ? singleListing.details?.numRooms
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Kitchen</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.numKitchens
                          ? singleListing.details?.numKitchens
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Bathroom Detail</p>
                      <p className="text-black font-normal">
                        {`${singleListing.details?.bathrooms[1].count} ${singleListing.details?.bathrooms[1].pieces} pc, ${singleListing.details?.bathrooms[1].level}`}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Basement Type</p>
                      <p className="text-black font-normal">Apartment</p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 text-start">
                  <h1 className="text-xl font-semibold">Utilities Central</h1>
                  <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12 max-sm:grid-cols-1">
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Heating</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.heating
                          ? singleListing.details?.heating
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Water</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.waterSource
                          ? singleListing.details?.waterSource
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Cooling</p>
                      <p className="text-black font-normal">
                        {singleListing.details.airConditioning
                          ? singleListing.details.airConditioning
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 text-start">
                  <h1 className="text-xl font-semibold">Parking Details</h1>
                  <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12 max-sm:grid-cols-1    ">
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Driveway</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.driveway
                          ? singleListing.details?.driveway
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Garage Type</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.garage
                          ? singleListing.details?.garage
                          : "-"}
                      </p>
                    </div>
                    {singleListing.details?.numGarageSpaces > 0 && (

                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Garage</p>
                      <p className="text-black font-normal">
  {singleListing.details?.numGarageSpaces
    ? Math.trunc(singleListing.details.numGarageSpaces) 
    : "-"}
</p>
                    </div>
                    )}
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Parking Spaces</p>
                      {/* <p className="text-black font-normal">
                        {singleListing.details?.numParkingSpaces
                          ? singleListing.details?.numParkingSpaces
                          : "-"}
                      </p> */}
                      <p className="text-black font-normal">
  {singleListing.details?.numParkingSpaces
    ? Math.trunc(singleListing.details.numParkingSpaces) // Ensures whole numbers
    : "-"}
</p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 text-start">
                  <h1 className="text-xl font-semibold">Other Details</h1>
                  <div className="w-full grid-cols-2 grid mt-6 gap-3 gap-x-12">
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Den</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.den === "Y" ? "Yes" : "No"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Swimming Pool</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.swimmingPool === "Y"
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Furnished</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.furnished === "Y"
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between p-3 border border-b-gray-300 border-r-0 border-l-0 border-t-0">
                      <p className="font-medium">Family Rooms</p>
                      <p className="text-black font-normal">
                        {singleListing.details?.familyRoom === "Y"
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 text-start">
                  <h1 className="text-xl font-semibold">Map View</h1>
                  <div className="w-full rounded-lg mt-10 h-96  p-7 max-sm:p-0 md:p-0 lg:p-7">
                    <SinglePropertiesMap coordinates={coordinates} />
                  </div>
                </div>
              </div>
              
              <div className={`flex flex-col p-2 gap-4 lg:w-[23%] w-full h-full font-open-sans ${
    isPrintVisible ? "" : "hide_on"
  }`} >
                <div className="flex flex-col gap-4 p-8 border border-gray-300 rounded-lg md:m-0 lg:m-3">
                  <p>Request a Tour as early as Today</p>
                  <button
                    className="bg-Amber-400/70 font-semibold text-black p-3 rounded-lg"
                    style={{fontFamily:'"Roboto","sans-serif"'}}
                    onClick={() => handleRequestTour("tour")}
                  >
                    Request Tour
                  </button>
                  <button
                    className="bg-white border-Amber-400 font-semibold border text-black p-3 rounded-lg"
                    style={{fontFamily:'"Roboto","sans-serif"'}}
                    onClick={() => handleRequestTour("info")}
                  >
                    Request Info
                  </button>
                </div>

                {/* similar listings */}
                <div className=" mt-3 md:p-0 md:px-0">
                  <div className="flex justify-between items-center p-2 md:p-0">
                  <h1 className="text-2xl font-bold">Similar Listings</h1>
                    {/* <div className="flex gap-3">
                    <div className={`cursor-pointer text-black ${currentIndex > 0 ? '' : 'opacity-40'}`}
                      onClick={() => currentIndex > 0 && slider.current.slickPrev()}>
                      <FaArrowLeft color='#00000 ' size={23} />
                    </div>
                    <div className={`cursor-pointer text-black hover:opacity-70 ${currentIndex < similarListings.length - 1 ? '' : 'opacity-40'}`}
                      onClick={() => currentIndex < similarListings.length - 1 && slider.current.slickNext()}>
                      <FaArrowRight color='#000000 ' size={23} />
                    </div>

                  </div> */}
                  </div>
                  {similarlistingLoading ? (
                    Array.from({ length: 2 }).map((_, index) => (
                      <LoaderCard key={index} />
                    ))
                  ) : (
                    <div className="md:grid md:grid-cols-2 lg:grid-cols-1 md:gap-7 w-[100%] ">
                      {similarListings === 0 ? (
                        <div>...Oops no similar data found </div>
                      ) : (
                        <>
                          {/* <Slider {...sliderSettings} ref={slider}> */}
                          {similarListings.slice(0, 2).map((item, index) => (
                            <div key={index} className="md:w-[100%] ">
                              <div className="h-[350px] cursor-pointer m-2 relative bg-cover bg-center rounded-2xl overflow-hidden  md:w-[100%]" onClick={() =>
                                    window.open(
                                      `/property/${item.mlsNumber}`,
                                      "_blank"
                                    )
                                  }>
                                <img
                                  src={item.images[0]}
                                  alt="home"
                                  className="rounded-2xl w-full h-full object-cover transform hover:scale-105"
                                  style={{
                                    transition:
                                      "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
                                  }}
                                  onClick={() =>
                                    window.open(
                                      `/property/${item.mlsNumber}`,
                                      "_blank"
                                    )
                                  }
                                />
                                <div className="p-4 rounded-b-xl absolute top-[55%] w-full">
                                  <div className="w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm h-[115px]">
                                    <div className="flex justify-start items-center">
                                      <h2 className="text-lg text-[#006938] font-bold whitespace-nowrap">
                                        {formatPrice(item.listPrice)}
                                      </h2>
                                    </div>
                                    <div className="flex gap-1 items-center">
                                      <GrLocation
                                        size={20}
                                        className="shrink-0"
                                      />
                                      <h2
                                        className="text-base max-2xl:text-sm  overflow-hidden text-start text-ellipsis font-normal whitespace-nowrap"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}
                                      >{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
                                    </div>
                                    <div className="flex items-center justify-between">
                                      <div className="flex items-center gap-2">
                                  
                                         {item.details?.numBedrooms > 0 && (
                                            <div className='flex gap-1 items-center'>
                                              <IoBedOutline size={25} />
                                              <h4
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content={item.details.numBedrooms}
                                              >
                                                {item.details.numBedrooms}
                                              </h4>
                                            </div>
                                          )}
                                        
                                          {item.details?.numBedrooms > 0 && item.details?.numBathrooms > 0 && (
                                            <span className='text-gray-300'>|</span>
                                          )}
                                        
                                          {item.details?.numBathrooms > 0 && (
                                            <div className='flex gap-1 items-center'>
                                              <LiaBathSolid size={25} />
                                              <h4
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content={item.details.numBathrooms}
                                              >
                                                {item.details.numBathrooms}
                                              </h4>
                                            </div>
                                          )}

                                        
                                        {/* <span className="text-gray-300">|</span> */}
                                        {/* <div className="flex gap-1 items-center">
                                          <BiArea size={20} />
                                          <h4
                                            className="truncate"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={
                                              item.lot.width
                                                ? `${item.lot.depth} x ${item.lot.width}`
                                                : "0 x 0"
                                            }
                                          >
                                            {item.lot.width
                                              ? `${item.lot.depth} x ${item.lot.width}`
                                              : "0 x 0"}
                                          </h4>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {/* </Slider> */}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            </div>
            {/* fetaure listings */}

            <div className="mt-10 mb-5">
              <h1 className="text-xl font-semibold text-start">
                Featured Listings
              </h1>
              <div className="flex justify-end items-center">
                <h3
                  className="text-lg text-end px-7 pr-8 hover:text-Stone-500 cursor-pointer"
                  onClick={() => navigate("/buy")}
                >
                  See More
                </h3>
              </div>

              <div className="mt-2">
                <div className="grid grid-cols-4 gap-7 px-7 max-sm:grid-cols-1 max-md:grid-cols-2 xl2:grid-cols-4  max-lg:grid-cols-2 2xl:grid-cols-4 place-items-center md:px-0 lg:px-7">
                  {loading
                    ? Array.from({ length: 3 }).map((_, index) => (
                        <LoaderCard key={index} />
                      ))
                    : featureListings.map((item, index) => (
                        <div
                          key={index}
                          className="w-full rounded-xl shadow-lg "
                          onClick={() =>
                            window.open(`/property/${item.mlsNumber}`, "_blank")
                          }
                        >
                          <div className="h-[350px] relative bg-cover bg-center rounded-2xl overflow-hidden cursor-pointer">
                            <img
                              src={item.images[0]}
                              alt="home"
                              className="rounded-2xl w-full h-full object-cover transform hover:scale-105"
                              style={{
                                transition:
                                  "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
                              }}
                            />
                            <div className="p-4 rounded-b-xl absolute top-[55%] w-full">
                              <div className="w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm">
                                <div className="flex justify-start items-center">
                                  <h2 className="text-lg text-[#006938] font-bold whitespace-nowrap">
                                    {formatPrice(item.listPrice)}
                                  </h2>
                                </div>
                                <div className="flex gap-1 items-center">
                                  <GrLocation size={20} className="shrink-0" />
                                  <h2
                                    className="text-sm overflow-hidden text-start text-ellipsis font-normal whitespace-nowrap"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}
                                  >{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
                                </div>
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center gap-2">
                                           {item.details.numBedrooms > 0 && (
                                   
                                                                   <div className="flex gap-1 items-center">
                                                                     <IoBedOutline size={20} />
                                                                     <h4
                                                                       data-tooltip-id="my-tooltip"
                                                                       data-tooltip-content={
                                                                         item.details.numBedrooms
                                                                           ? item.details.numBedrooms
                                                                           : 0
                                                                       }
                                                                     >
                                                                       {item.details.numBedrooms
                                                                         ? item.details.numBedrooms
                                                                         : 0}
                                                                     </h4>
                                                                   </div>
                                                                   )}
                                   
                                                                   {item.details.numBathrooms > 0 && (
                                   
                                                                   <>
                                                                   <span className="text-gray-300">|</span>
                                                                   <div className="flex gap-1 items-center">
                                                                     <LiaBathSolid size={20} />
                                                                     <h4
                                                                       data-tooltip-id="my-tooltip"
                                                                       data-tooltip-content={
                                                                         item.details.numBathrooms
                                                                           ? item.details.numBathrooms
                                                                           : 0
                                                                       }
                                                                     >
                                                                       {item.details.numBathrooms
                                                                         ? item.details.numBathrooms
                                                                         : 0}
                                                                     </h4>
                                                                   </div>
                                                                   </>
                                                                   )}
                                    {/* <span className="text-gray-300">|</span>
                                    <div className="flex gap-1 items-center">
                                      <BiArea size={20} />
                                      <h4
                                        className="truncate"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={
                                          item.lot.width
                                            ? `${item.lot.depth} x ${item.lot.width}`
                                            : "0 x 0"
                                        }
                                      >
                                        {item.lot.width
                                          ? `${item.lot.depth} x ${item.lot.width}`
                                          : "0 x 0"}
                                      </h4>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
                <Tooltip id="my-tooltip" />
              </div>
            </div>
            <RequestTourInfo
              open={isRequestTourOpen}
              title={title}
              handleClose={() => setIsRequestTourOpen(false)}
            />
          </div>
        )}

        <div>
          
        </div>
      </div>
     
     

    </>
  );
};

export default PropertyView;
