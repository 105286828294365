

// const RequestTourInfo = ({ open, handleClose, title }) => {
//     const [formData, setFormData] = useState({
//         fullName: '',
//         email: '',
//         phoneNumber: '',
//         date: '',
//         time: '',
//         comments: '',
//         subscribe: false,
//     });
//     const [error, setError] = useState('');
//     const [successMessage, setSuccessMessage] = useState('fullName');

//     const handleChange = (e) => {
//         const { name, value, type, checked } = e.target;
//         setFormData({
//             ...formData,
//             [name]: type === 'checkbox' ? checked : value,
//         });
//         if (!validateForm()) return;

//     };

//     const validateForm = () => {
//         console.log(error)
//         const { fullName, email, phoneNumber, date, time } = formData;
//         if (!fullName || !email || !phoneNumber || (title === 'tour' && (!date || !time))) {
//             setError('Please fill out all required fields.');
//             return false;
//         }
//         if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
//             setError('email');
//             return false;
//         }
//         if (!/^\d{10}$/.test(phoneNumber)) {
//             setError('phoneNumber');
//             return false;
//         }
//         setError('');
//         return true;
//     };

//     const handleSubmit = async () => {
//         if (!validateForm()) return;

//         try {
//             console.log(formData);
//             setSuccessMessage('Your request has been submitted!');
//             setFormData({
//                 fullName: '',
//                 email: '',
//                 phoneNumber: '',
//                 date: '',
//                 time: '',
//                 comments: '',
//                 subscribe: false,
//             });
//             handleClose();
//         } catch (error) {
//             setError('Something went wrong. Please try again.');
//         }
//     };
//     const handleCloseFarm = () => {
//         setFormData({
//             fullName: '',
//             email: '',
//             phoneNumber: '',
//             date: '',
//             time: '',
//             comments: '',
//             subscribe: false,
//         });
//         handleClose();
//     }

//     if (!open) return null;

//     return (
//         <div
//             className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50 overflow-y-auto"
//             role="dialog"
//             aria-modal="true"
//             aria-labelledby="tourRequestTitle"
//         >
//             <div className="bg-white rounded-xl shadow-2xl w-full max-w-lg mx-4 max-h-[90vh] flex flex-col">
//                 {/* Header */}
//                 <div className="px-6 py-4 border-b border-gray-200 rounded-t-xl bg-gradient-to-r from-Stone-500 to-Stone-700 text-white">
//                     <h2 id="tourRequestTitle" className="text-2xl font-semibold">
//                         {title === 'tour' ? 'Request a Tour' : 'Request Information'}
//                     </h2>
//                 </div>

//                 {/* Scrollable Content */}
//                 <div className="p-6 space-y-4 overflow-y-auto flex-1 text-start">
//                     {successMessage && <p className="text-green-500 text-sm">{successMessage}</p>}
//                     <p className='text-sm'>{title === 'tour' ? 'Either see it in-person or work with your agent to see it via video' : 'All Questions are texted in real time to our agent to ensure the fastest response possible.'}</p>

//                     <label className="block mb-2">
//                         <span className="text-gray-700 font-medium">Full Name</span>
//                         <input
//                             className="w-full p-3 border border-gray-300 rounded-lg mt-1 focus:outline-none focus:ring-2 focus:ring-Stone-500"
//                             autoFocus
//                             name="fullName"
//                             placeholder="Enter your full name"
//                             type="text"
//                             value={formData.fullName}
//                             onChange={handleChange}
//                             aria-label="Full Name"
//                             required
//                         />
//                     </label>

//                     <label className="block mb-2">
//                         <span className="text-gray-700 font-medium">Email</span>
//                         <input
//                             className={`w-full p-3 border border-gray-300 rounded-lg mt-1 focus:outline-none focus:ring-2 focus:ring-Stone-500 ${ error === 'email' ? 'border-Red-700 focus:ring-Red-700' : ''}`}
//                             name="email"
//                             placeholder="Enter your email address"
//                             type="email"
//                             value={formData.email}
//                             onChange={handleChange}
//                             aria-label="Email"
//                             required
//                         />
//                     </label>

//                     <label className="block mb-2">
//                         <span className="text-gray-700 font-medium">Phone Number</span>
//                         <input
//                             className={`w-full p-3 border border-gray-300 rounded-lg mt-1 focus:outline-none focus:ring-2 focus:ring-Stone-500 ${ error === 'phoneNumber' ? 'border-Red-700 focus:ring-Red-700' : ''}`}
//                             name="phoneNumber"
//                             placeholder="Enter your phone number"
//                             type="tel"
//                             value={formData.phoneNumber}
//                             onChange={handleChange}
//                             aria-label="Phone Number"
//                             required
//                         />
//                     </label>

//                     {/* Conditionally show Date and Time fields */}
//                     {title === 'tour' && (
//                         <div className="flex space-x-4">
//                             <label className="flex-1">
//                                 <span className="text-gray-700 font-medium">Date</span>
//                                 <input
//                                     className="w-full p-3 border border-gray-300 rounded-lg mt-1 focus:outline-none focus:ring-2 focus:ring-Stone-500"
//                                     name="date"
//                                     placeholder="Select a date"
//                                     type="date"
//                                     value={formData.date}
//                                     onChange={handleChange}
//                                     aria-label="Date"
//                                     required
//                                 />
//                             </label>

//                             <label className="flex-1">
//                                 <span className="text-gray-700 font-medium">Time</span>
//                                 <input
//                                     className="w-full p-3 border border-gray-300 rounded-lg mt-1 focus:outline-none focus:ring-2 focus:ring-Stone-500"
//                                     name="time"
//                                     placeholder="Select a time"
//                                     type="time"
//                                     value={formData.time}
//                                     onChange={handleChange}
//                                     aria-label="Time"
//                                     required
//                                 />
//                             </label>
//                         </div>
//                     )}

//                     <label className="block mb-2">
//                         <span className="text-gray-700 font-medium">Comments</span>
//                         <textarea
//                             className="w-full p-3 border border-gray-300 rounded-lg mt-1 focus:outline-none focus:ring-2 focus:ring-Stone-500"
//                             name="comments"
//                             placeholder="Additional comments"
//                             rows={4}
//                             value={formData.comments}
//                             onChange={handleChange}
//                             aria-label="Comments"
//                         />
//                     </label>

//                     {/* Newsletter and Privacy Policy Section */}
//                     <label className="flex items-start mt-4 space-x-2">
//                         <input
//                             type="checkbox"
//                             name="subscribe"
//                             checked={formData.subscribe}
//                             onChange={handleChange}
//                             className="mt-1"
//                         />
//                         <span className="text-gray-700 text-sm">
//                             I would also like to receive email newsletters and periodic updates about real estate in my area and other related communications from Johnston & Daniel Division, Royal LePage Real Estate Services Ltd., Brokerage. I understand I can withdraw my consent at any time.
//                         </span>
//                     </label>

//                     <p className="text-gray-600 text-sm mt-4">
//                         By submitting your message and personal details you are permitting us to contact you by these means in response to your inquiry.
//                     </p>

//                     <p className="text-gray-600 text-sm mt-2">
//                         In addition, you also agree to our{' '}
//                         <a href="/privacy-policy" className="text-Stone-500 hover:underline">Privacy Policy</a> and{' '}
//                         <a href="/terms-conditions" className="text-Stone-500 hover:underline">Terms & Conditions</a>.
//                     </p>
//                 </div>

//                 {/* Footer */}
//                 <div className="px-6 py-4 border-t border-gray-200 rounded-b-xl flex justify-end bg-gray-100">
//                     <button
//                         className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 focus:outline-none"
//                         onClick={handleCloseFarm}
//                     >
//                         Cancel
//                     </button>
//                     <button
//                         className={`px-4 py-2 bg-Stone-600 text-white rounded hover:bg-Stone-700 focus:outline-none ${error ? 'opacity-50': ''}`}
//                         onClick={handleSubmit}
//                     >
//                         Submit
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default RequestTourInfo;





import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const RequestTourInfo = ({ open, handleClose, title }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    countryCode: "+1",
    date: "",
    time: "",
    comments: "",
    subscribe: false,
  });

  const [error, setError] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [country, setCountry] = useState("US");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const { fullName, email, phoneNumber, date, time } = formData;
    const newError = {};

    if (!fullName.trim()) newError.fullName = "Full Name is required.";
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      newError.email = "Please enter a valid email address.";
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber))
      newError.phoneNumber = "Please enter a valid phone number.";
    if (title === "tour") {
      if (!date) newError.date = "Date is required.";
      if (!time) newError.time = "Time is required.";
    }

    setError(newError);
    return Object.keys(newError).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setSuccessMessage("Your request has been submitted!");
      setFormData({
        fullName: "",
        email: "",
        phoneNumber: "",
        countryCode: "+1",
        date: "",
        time: "",
        comments: "",
        subscribe: false,
      });
      setError({});
      handleClose();
    } catch (err) {
      setError({ general: "Something went wrong. Please try again." });
    }
  };

  const handleCloseForm = () => {
    setFormData({
      fullName: "",
      email: "",
      phoneNumber: "",
      countryCode: "+1",
      date: "",
      time: "",
      comments: "",
      subscribe: false,
    });
    setError({});
    handleClose();
  };

  if (!open) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50 overflow-y-auto"
      role="dialog"
      aria-modal="true"
      aria-labelledby="tourRequestTitle"
    >
      <div className="bg-white rounded-xl shadow-2xl w-full max-w-lg mx-4 max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-200 rounded-t-xl bg-gradient-to-r from-Stone-500 to-Stone-700 text-white">
          <h2 id="tourRequestTitle" className="text-2xl font-semibold">
            {title === "tour" ? "Request a Tour" : "Request Information"}
          </h2>
        </div>

        {/* Scrollable Content */}
        <div className="p-6 space-y-4 overflow-y-auto  flex-1 text-start">
          {successMessage && (
            <p className="text-green-500 text-sm">{successMessage}</p>
          )}
          <p className="text-sm">
            {title === "tour"
              ? "Either see it in-person or work with your agent to see it via video."
              : "All Questions are texted in real time to our agent to ensure the fastest response possible."}
          </p>

          <label className="block mb-2">
            <span className="text-gray-700 font-medium">Full Name </span>
            <span className="text-Red-700">*</span>
            <input
              className={`w-full border p-2 rounded-md text-gray-700 focus:bg-gray-200 ${
                error.fullName ? "border-Red-700" : ""
              }`}
              autoFocus
              name="fullName"
              placeholder="Enter your full name"
              type="text"
              value={formData.fullName}
              onChange={handleChange}
              aria-label="Full Name"
              required
            />
            {error.fullName && (
              <p className="text-Red-700 text-sm">{error.fullName} *</p>
            )}
          </label>

          <label className="block mb-2">
            <span className="text-gray-700 font-medium">Email </span>
            <span className="text-Red-700">*</span>
            <input
              className={`w-full border p-2 rounded-md text-gray-700 focus:bg-gray-200 ${
                error.email ? "border-Red-700" : ""
              }`}
              name="email"
              placeholder="Enter your email address"
              type="email"
              value={formData.email}
              onChange={handleChange}
              aria-label="Email"
              required
            />
            {error.email && (
              <p className="text-Red-700 text-sm">{error.email} *</p>
            )}
          </label>

          <label className="block mb-2">
            <span className="text-gray-700 font-medium">Phone Number </span>
            <span className="text-Red-700">*</span>
            <PhoneInput
              placeholder="Enter phone number"
              className={`w-full border p-2 rounded-md text-gray-700 focus:bg-gray-200 ${
                error.phoneNumber ? "border-Red-700" : ""
              }`}
              value={formData.phoneNumber}
              onChange={(value) =>
                setFormData({ ...formData, phoneNumber: value })
              }
              defaultCountry={country}
            />
            {error.phoneNumber && (
              <p className="text-Red-700 text-sm">{error.phoneNumber} *</p>
            )}
          </label>

          {title === "tour" && (
            <div className="flex space-x-4">
              <label className="flex-1">
                <span className="text-gray-700 font-medium">Date </span>
                <span className="text-Red-700">*</span>
                <input
                  className={`w-full border p-2 rounded-md text-gray-700 focus:bg-gray-200 ${
                    error.date ? "border-Red-700" : ""
                  }`}
                  name="date"
                  placeholder="Select a date"
                  type="date"
                  value={formData.date}
                  onChange={handleChange}
                  aria-label="Date"
                  required
                />
                {error.date && (
                  <p className="text-Red-700 text-sm">{error.date} *</p>
                )}
              </label>

              <label className="flex-1">
                <span className="text-gray-700 font-medium">Time </span>
                <span className="text-Red-700">*</span>
                <input
                 className={`w-full border p-2 rounded-md text-gray-700 focus:bg-gray-200 ${
                    error.time ? "border-Red-700" : ""
                  }`}
                  name="time"
                  placeholder="Select a time"
                  type="time"
                  value={formData.time}
                  onChange={handleChange}
                  aria-label="Time"
                  required
                />
                {error.time && (
                  <p className="text-Red-700 text-sm">{error.time} *</p>
                )}
              </label>
            </div>
          )}

          <label className="block mb-2">
            <span className="text-gray-700 font-medium">Comments </span>
            <textarea
              className="w-full p-3 border border-gray-300 rounded-lg mt-1 focus:outline-none focus:ring-2 focus:ring-Stone-500"
              name="comments"
              placeholder="Additional comments"
              rows={4}
              value={formData.comments}
              onChange={handleChange}
              aria-label="Comments"
            />
          </label>

          <label className="flex items-start mt-4 space-x-2">
            <input
              type="checkbox"
              name="subscribe"
              checked={formData.subscribe}
              onChange={handleChange}
              className="h-5 w-5"
            />
            <span className="text-gray-600 text-sm">
              Subscribe to our newsletter.
            </span>
          </label>

          <div className="text-xs text-gray-500 mt-2">
            By submitting, you agree to our{" "}
            <a
              href="/privacy-policy"
              className="text-Stone-500 hover:underline"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>

        <div className="px-6 py-4 border-t border-gray-200 rounded-b-xl flex justify-end bg-gray-100">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 focus:outline-none"
            onClick={handleCloseForm}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 bg-Stone-600 text-white rounded hover:bg-Stone-700 focus:outline-none ${
              Object.keys(error).length ? "opacity-50" : ""
            }`}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestTourInfo;

