import img1 from "../assets/images/9.6-2-1-1-525x328.webp";
import img2 from "../assets/images/10-4-1-1-1.webp";
import img3 from "../assets/images/j-1-1-1-525x328.webp";
import img4 from "../assets/images/2.6-6-1-1-525x328.webp";
import img5 from "../assets/images/5.3-3-1-1-525x328.webp";
import img6 from "../assets/images/4-1-1-1-525x328.webp";
import img7 from "../assets/images/sell-1-1-1-525x328.webp";
import img8 from "../assets/images/x-4-1-1-525x328.webp";
import img9 from "../assets/images/vecteezy_happy-asian-couple-client-tenant-buy-home-realtor-hand_25133377.jpg";
import React, { useEffect, useState } from "react";
import { GrLocation } from "react-icons/gr";
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
// import { BiArea } from "react-icons/bi";
import { getListings } from "../service";
import LoaderCard from "../components/loader";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const BlogPage = () => {
  const navigate = useNavigate();
  const blogArray = [
    {
      key: "Best areas for families with kids",
      img: img1,
      mainHead: "Best areas for families with kids",
      date: "March 4, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5  ",
      id: 1,
    },
    {
      key: "The top neighborhoods",
      img: img2,
      mainHead: "The top neighborhoods",
      date: "March 4, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",

      id: 2,
    },
    {
      key: "Buyer's Guide",
      img: img3,
      mainHead: "Buyer's Guide",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5  ",
      id: 3,
    },
    {
      key: "Seller's Guide",
      img: img4,
      mainHead: "Seller's Guide",
      date: "March 4, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
      id: 4,
    },
    {
      key: "Reasons to sell before New Year",
      img: img5,
      mainHead: "Reasons to sell before New Year",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
      id: 5,
    },
    {
      key: "What to look for in an agent",
      img: img8,
      mainHead: "What to look for in an agent",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
      id: 6,
    },
    {
      key: "The best of Torront’s vibrant cities from coast to coast",
      img: img7,
      mainHead: "The best of Torront’s vibrant cities from coast to coast",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5  ",
      id: 7,
    },
    {
      key: "The Top 10 Attraction in Monteral",
      img: img6,
      mainHead: "The Top 10 Attraction in Monteral",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
      id: 8,
    },
  ];
  const [similarListings, setSimilarListings] = useState([]);
  const [similarlistingLoading, setSimilarListingLoading] = useState(false);
  useEffect(() => {
    getFeatureListings();
  }, []);

  const handleNavigate = (key) => {
    const selectedBlog = blogArray.find((blog) => blog.key === key);
    console.log(selectedBlog,"selectedBlogselectedBlog");
  
    navigate(`/neighborhoods/${key}`)
  };

  const getFeatureListings = async () => {
    setSimilarListingLoading(true);
    const filterQuery =
      "&propertyType=Detached&minBeds=5&minBaths=5&minPrice=5000000&maxPrice=10000000";
    try {
      const result = await getListings(1, 3, filterQuery);
      setSimilarListings(result.data.listings);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSimilarListingLoading(false);
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(price);
  };

  const itemsPerPage = 4;
  const totalPages = Math.ceil(blogArray.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const currentBlogs = blogArray.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`w-12 h-12 rounded-full text-sm ${
            i === currentPage ? "bg-Green-900 text-white" : "bg-gray-200"
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };
  return (
    <div className="">
      <div
        className=" bg-cover bg-center animate-fadeIn h-[600px] flex items-center "
        style={{
          backgroundImage: `url(${img9})`,
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 h-[600px]"></div>

        <div className="relative text-center w-full">
          <h3
            style={{
              color: " #FFFFFF",
              fontFamily: '"Bodoni Moda", "Sans-serif"',
              fontSize: "67px",
              fontWeight: " 400",
              lineHeight: "1.1em",
              textShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
            }}
            className=" mt-3 text-white"
          >
            Real Estate Blog
          </h3>
          <h1 className="text-lg text-white">
            Read about the latest industry news
          </h1>
        </div>
      </div>

      <div
        className=" px-3 container mt-32 flex gap-10 items-start max-sm:flex-col md:flex-col lg:flex-row max-sm:items-center"
        style={{ marginTop: "50px" }}
      >
        <div className="w-[70%] max-sm:w-[100%] md:w-[100%] lg:w-[70%]">
          <div className="mx-3">
            <h1
              className="text-4xl font-semibold text-start"
              style={{ fontFamily: "Frank Ruhl Libre" }}
            >
              Blog List
            </h1>
          </div>
          <div className="grid grid-cols-2 gap-6 max-sm:grid-cols-1 ">
            {currentBlogs.map((item, index) => (
              <div
                key={index}
                className="p-2 rounded-lg  bg-white shadow-md cursor-pointer max-sm:w-[100%]"
                onClick={() => handleNavigate(item.key)}
              >
                <div className="h-[280px] p-2">
                  <img
                    src={item.img}
                    alt="home"
                    className="rounded-2xl w-full h-full object-cover transform hover:scale-105"
                    style={{
                      transition:
                        "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
                    }}
                  />
                </div>
                <div className="mt-2 bg-white text-start p-2">
                  <h1 className="text-1xl text-black font-medium hover:text-gold-400">
                    {item.mainHead}
                  </h1>
                  <h3
                    style={{ fontSize: "13px", color: " #464646" }}
                    className="text-base mt-2"
                  >
                    {item.date}
                  </h3>
                  <h3
                    style={{ fontSize: "13px", color: " #464646" }}
                    className="text-base mt-2"
                  >
                    {" "}
                    {item.content}
                  </h3>
                  <p
                    className="text-1x1 mt-2 flex cursor-pointer "
                    onClick={() => handleNavigate(item.key)}
                  >
                    Continue Reading{" "}
                    <span className="ml-2">
                      {<MdKeyboardArrowRight size={24} />}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex  gap-5 justify-center mt-5 items-center">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="bg-Green-900 p-4 rounded-full disabled:opacity-50"
            >
              <FaArrowLeft  color='#ffffff' />
            </button>

            {renderPageNumbers()}

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="bg-Green-900 p-4 rounded-full disabled:opacity-50"
            >
              <FaArrowRight  color='#ffffff'/>
            </button>
          </div>
        </div>
        <div className="w-[30%] md:w-[100%] lg:w-[30%] pt-2 px-4 shadow-lg h-auto max-sm:w-[100%]">
          <div className="mx-3">
            <h1
              className="text-3xl font-medium text-start mt-4"
              style={{ fontFamily: "Frank Ruhl Libre" }}
            >
              Latest Listings
            </h1>
          </div>
          <div className="p-1 px-3">
            <div className="flex justify-between items-center p-2"></div>
            {similarlistingLoading ? (
              Array.from({ length: 1 }).map((_, index) => (
                <LoaderCard key={index} />
              ))
            ) : (
              <div>
                {similarListings === 0 ? (
                  <div>...Oops no similar data found </div>
                ) : (
                  <>
                    {similarListings.map((item, index) => (
                      <div key={index}>
                        <div className="h-[350px] cursor-pointer m-2 relative bg-cover bg-center rounded-2xl overflow-hidden">
                          <img
                            src={item.images[0]}
                            alt="home"
                            className="rounded-2xl w-full h-full object-cover transform hover:scale-105"
                            style={{
                              transition:
                                "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
                            }}
                            onClick={() =>
                              window.open(
                                `/property/${item.mlsNumber}`,
                                "_blank"
                              )
                            }
                          />
                          <div className="p-4 rounded-b-xl absolute top-[55%] w-full">
                            <div className="w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm">
                              <div className="flex justify-start items-center">
                                <h2 className="text-lg text-[#006938] font-bold whitespace-nowrap">
                                  {formatPrice(item.listPrice)}
                                </h2>
                              </div>
                              <div className="flex gap-1 items-center">
                                <GrLocation size={20} className="shrink-0" />
                                <h2
                                  className="text-base max-2xl:text-sm  overflow-hidden text-start text-ellipsis font-normal whitespace-nowrap"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}
                                >{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
                              </div>
                              <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                  <div className="flex gap-1 items-center">
                                    <IoBedOutline size={20} />
                                    <h4
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={
                                        item.details.numBedrooms
                                          ? item.details.numBedrooms
                                          : 0
                                      }
                                    >
                                      {item.details.numBedrooms
                                        ? item.details.numBedrooms
                                        : 0}
                                    </h4>
                                  </div>
                                  <span className="text-gray-300">|</span>
                                  <div className="flex gap-1 items-center">
                                    <LiaBathSolid size={20} />
                                    <h4
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={
                                        item.details.numBathrooms
                                          ? item.details.numBathrooms
                                          : 0
                                      }
                                    >
                                      {item.details.numBathrooms
                                        ? item.details.numBathrooms
                                        : 0}
                                    </h4>
                                  </div>
                                  {/* <span className="text-gray-300">|</span>
                                  <div className="flex gap-1 items-center">
                                    <BiArea size={20} />
                                    <h4
                                      className="truncate"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={
                                        item.lot.width
                                          ? `${item.lot.depth} x ${item.lot.width}`
                                          : "0 x 0"
                                      }
                                    >
                                      {item.lot.width
                                        ? `${item.lot.depth} x ${item.lot.width}`
                                        : "0 x 0"}
                                    </h4>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;

// import React, { useEffect, useState } from "react";
// import { MdKeyboardArrowRight,MdKeyboardArrowLeft,  } from "react-icons/md";
// import { useNavigate } from "react-router-dom";
// import img1 from "../assets/images/9.6-2-1-1-525x328.webp";
// import img2 from "../assets/images/10-4-1-1-1.webp";
// import img3 from "../assets/images/j-1-1-1-525x328.webp";
// import img4 from "../assets/images/2.6-6-1-1-525x328.webp";
// import img5 from "../assets/images/5.3-3-1-1-525x328.webp";
// import img6 from "../assets/images/4-1-1-1-525x328.webp";
// import img7 from "../assets/images/sell-1-1-1-525x328.webp";
// import img8 from "../assets/images/x-4-1-1-525x328.webp";
// import img9 from "../assets/images/vecteezy_happy-asian-couple-client-tenant-buy-home-realtor-hand_25133377.jpg";

// const BlogPage = () => {
//   const navigate = useNavigate();

//   const blogArray = [
//     { key: "Best areas for families with kids", img: img1, mainHead: "Best areas for families with kids", date: "March 4, 2016", content: "Content for blog 1", id: 1 },
//     { key: "The top neighborhoods", img: img2, mainHead: "The top neighborhoods", date: "March 4, 2016", content: "Content for blog 2", id: 2 },
//     { key: "Buyer's Guide", img: img3, mainHead: "Buyer's Guide", date: "May 27, 2016", content: "Content for blog 3", id: 3 },
//     { key: "Seller's Guide", img: img4, mainHead: "Seller's Guide", date: "March 4, 2016", content: "Content for blog 4", id: 4 },
//     { key: "Reasons to sell before New Year", img: img5, mainHead: "Reasons to sell before New Year", date: "May 27, 2016", content: "Content for blog 5", id: 5 },
//     { key: "What to look for in an agent", img: img8, mainHead: "What to look for in an agent", date: "May 27, 2016", content: "Content for blog 6", id: 6 },
//     { key: "How to evaluate your home", img: img7, mainHead: "How to evaluate your home", date: "May 27, 2016", content: "Content for blog 7", id: 7 },
//     { key: "What’s your property worth?", img: img6, mainHead: "What’s your property worth?", date: "May 27, 2016", content: "Content for blog 8", id: 8 },
//   ];

//   const handleNavigate = (key) => {
//     const selectedBlog = blogArray.find(blog => blog.key === key);
//     if (selectedBlog) {
//       navigate(`/neighborhoods/${key}`, { state: { selectedblog: selectedBlog } });
//     }
//   };
//   const itemsPerPage = 4; // Number of blogs per page
//   const totalPages = Math.ceil(blogArray.length / itemsPerPage);
//   const [currentPage, setCurrentPage] = useState(1);

//   // Get the current page items
//   const currentBlogs = blogArray.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   // Handle page change
//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   return (
//     <div>
//       <div
//         className="bg-cover bg-center h-[600px] flex items-center"
//         style={{ backgroundImage: `url(${img9})` }}
//       >
//         <div className="absolute inset-0 bg-black bg-opacity-50 h-[600px]"></div>
//         <div className="relative text-center w-full">
//           <h3
//             style={{
//               color: "#FFFFFF",
//               fontFamily: '"Bodoni Moda", "Sans-serif"',
//               fontSize: "67px",
//               fontWeight: "400",
//               lineHeight: "1.1em",
//               textShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
//             }}
//             className="mt-3 text-white"
//           >
//             Real Estate Blog
//           </h3>
//           <h1 className="text-lg text-white">
//             Read about the latest industry news
//           </h1>
//         </div>
//       </div>

//       <div className="container mt-10">
//         <h1 className="text-4xl font-semibold text-start" style={{ fontFamily: "Frank Ruhl Libre" }}>
//           Blog List
//         </h1>
//         <div className="grid grid-cols-2 gap-6 mt-5">
//           {currentBlogs.map((item, index) => (
//             <div key={index} className="p-2 rounded-lg bg-white shadow-md">
//               <div className="h-[280px] p-2">
//                 <img
//                   src={item.img}
//                   alt="home"
//                   className="rounded-2xl w-full h-full object-cover transform hover:scale-105"
//                   style={{ transition: "transform .5s ease" }}
//                 />
//               </div>
//               <div className="mt-2 text-start p-2">
//                 <h1 className="text-xl text-black font-medium hover:text-gold-400">
//                   {item.mainHead}
//                 </h1>
//                 <h3 style={{ fontSize: "13px", color: "#464646" }}>{item.date}</h3>
//                 <h3 style={{ fontSize: "13px", color: "#464646" }}>{item.content}</h3>
//                 <p
//                   className="text-xl mt-2 flex cursor-pointer"
//                   onClick={() => handleNavigate(item.key)}
//                 >
//                   Continue Reading{" "}
//                   <span className="ml-2">
//                     {<MdKeyboardArrowRight size={24} />}
//                   </span>
//                 </p>
//               </div>
//             </div>
//           ))}
//         </div>

//         <div className="flex justify-center mt-5 items-center">

//         <button
//           onClick={handlePrevPage}
//           disabled={currentPage === 1}
//           className={`p-2 mx-2 rounded-full ${
//             currentPage === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
//           }`}
//         >
//           <MdKeyboardArrowLeft size={24} />
//         </button>

//         {/* Page Numbers */}
//         <div className="flex items-center space-x-2">
//           {[...Array(totalPages).keys()].map((num) => (
//             <button
//               key={num}
//               onClick={() => handlePageChange(num + 1)}
//               className={`p-2 rounded-full ${
//                 currentPage === num + 1
//                   ? "bg-blue-500 text-white"
//                   : "bg-gray-200 text-black"
//               }`}
//             >
//               {num + 1}
//             </button>
//           ))}
//         </div>

//         {/* Next Button */}
//         <button
//           onClick={handleNextPage}
//           disabled={currentPage === totalPages}
//           className={`p-2 mx-2 rounded-full ${
//             currentPage === totalPages ? "bg-gray-300" : "bg-blue-500 text-white"
//           }`}
//         >
//           <MdKeyboardArrowRight size={24} />
//         </button>
//       </div>
//       </div>
//     </div>
//   );
// };

// export default BlogPage;
