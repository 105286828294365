import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import { NextArrow, PrevArrow } from './customArrows';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";


const ImageSlider = ({ images,initialIndex }) => {
    
    const slider = React.useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        arrows: false,
        initialSlide: initialIndex || 0,
        afterChange: (index) => setCurrentIndex(index),
    };
    

    return (
        <div  className='m-auto lg:w-[80%] h-auto lg:mt-auto md:mt-[10%] mt-[50%] w-[90%] ' >
            <Slider {...settings} ref={slider}>
                {images.map((image, index) => (
                    <div key={index}>
                        <img
                            src={image}
                            alt={`Slide ${index}`}
                            style={{ width: '100%'}} // Adjust the image to fit the container
                        />
                    </div>

                ))}


            </Slider>
            <div className={`p-4 rounded-lg bg-Indigo-800/70 absolute top-[50%] left-[90%] cursor-pointer hover:opacity-70 ${currentIndex < images.length - 1 ? '' : 'opacity-40'}`}
            onClick={() => currentIndex < images.length - 1 && slider.current.slickNext()}>
                <FaArrowRight color='#ffffff '/>
            </div>
            <div className={`p-4 rounded-lg bg-Indigo-800/70 absolute top-[50%] right-[90%] cursor-pointer ${currentIndex > 0 ? '' : 'opacity-40'}`} 
            onClick={() => currentIndex > 0 && slider.current.slickPrev()}>
                <FaArrowLeft color='#ffffff '/>
            </div>
        </div>
    );
}

export default ImageSlider;
