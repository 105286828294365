import logo from '../assets/images/J_D-Logo_15_-WHITE-BG-Transparent-bg.webp';
import balcklogo from '../assets/images/J_D-Logo_15_-black-BG-Transparent-bg.webp'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useLocation, matchPath } from 'react-router-dom';
import AuthDialog from './Login-SignIn';
import { useAuth } from '../hooks/AuthContext';
import { FaRegUserCircle } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";

const HeaderComponent = () => {
  const { isAuthenticated, logout } = useAuth();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [initialForm, setInitialForm] = useState('login');
  const [showProfie, setShowProfile] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const isActive = (path) => location.pathname === path;


  const openDialog = (formType) => {
    console.log(formType);
    setInitialForm(formType);
    setIsDialogOpen(true);
  };
  const closeDialog = () => setIsDialogOpen(false);
  const switchForm = () => setIsLogin(!isLogin);


  useEffect(() => {
    if (isAuthenticated) {
      const userDetailsFromStorage = JSON.parse(localStorage.getItem('user'));
      setUserDetails(userDetailsFromStorage);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sidebarVariants = {
    open: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    },
    closed: {
      x: '100%',
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 40,
      }
    },
  };


  const propertyPaths = ['/property', '/branch', '/contact', '/ourteam', '/contact2', '/buy', '/buy2', '/ourstory', '/sell', '/blog'];
  if (window.screen.width < 768) {
    if (!propertyPaths.includes('/')) {
      propertyPaths.push('/');
    }
  } else {
    const index = propertyPaths.indexOf('/');
    if (index > -1) {
      propertyPaths.splice(index, 1);
    }
  }

  const isStaticPath = propertyPaths.includes(currentPath);
  const isDynamicPath = matchPath('/property/:id', currentPath) || matchPath('/profile/:id', currentPath) || matchPath('/neighborhoods/:id', currentPath);
  const isPropertyPage = isStaticPath || isDynamicPath;


  return (
    <div>
      {/* mobile screen */}
      <div className={`flex md:hidden w-full xl:text-lg items-center px-3 justify-between z-40 relative
      ${isPropertyPage ? 'text-black shadow-lg bg-white' : isScrolled ? 'text-[#545350]' : 'text-white'}`}>
        <img src={isScrolled || isPropertyPage ? balcklogo : logo} width={150} alt='logo' className='' onClick={() => navigate('/')}></img>
        <button className='p-2 border-gray-400 border rounded-md' onClick={() => setIsOpen(!isOpen)}><RxHamburgerMenu /></button>

        {/* Sidebar */}
        <motion.div
          className="fixed top-0 right-0 w-full h-full bg-white p-6"
          variants={sidebarVariants}
          initial="closed"
          animate={isOpen ? 'open' : 'closed'}
        >
          <h2 className="text-2xl mb-4 text-end font-bold text-gray-500 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>x</h2>
          <ul className='text-start text-gray-500 text-2xl font-bold'>
            <li className='p-2 cursor-pointer' onClick={() => { navigate('/buy'); setIsOpen(!isOpen); }}>BUY</li>
            <li className='p-2 cursor-pointer' onClick={() => {navigate('/sell'); setIsOpen(!isOpen)}}>SELL</li>
            <li className='p-2 cursor-pointer' onClick={() => { navigate('/ourteam');setIsOpen(!isOpen)}}>OUR TEAM</li>
            <li className='p-2 cursor-pointer' onClick={() => { navigate('/ourstory');setIsOpen(!isOpen)}}> OUR STORY</li>
            <li className='p-2 cursor-pointer' onClick={() => {navigate('/blog'); setIsOpen(!isOpen)}}>BLOG</li>
            <li className='p-2 cursor-pointer' onClick={() => {navigate('/contact'); setIsOpen(!isOpen)}}>CONTACT</li>
            <li>
              {isAuthenticated ? (
                <div className='p-2  text-base relative flex'>
                  <div className='flex items-center gap-2'>
                  <img
                    loading="lazy"
                    src={userDetails?.photoUrl}
                    alt="User Avatar"
                    className="rounded-full w-10 h-10  cursor-pointer"
                    onClick={() => setShowProfile(!showProfie)}
                    onError={(e) => {
                      console.error("Image failed to load:", userDetails?.photoUrl, e);
                    } } />
                        <p>
                        <li className='text-start text-gray-500 text-lg font-bold'><span>{userDetails?.displayName}</span></li>
                        </p>
                        </div>
                  {showProfie && (
                    <div className='absolute rounded-md border top-16'>
                      <ul className='p-0 m-0 bg-white text-black rounded-md'>
                        <li className='flex items-center gap-1 p-2 border-b border-gray-100 cursor-pointer hover:bg-gray-200' onClick={() => { logout(); setShowProfile(false); } }><FiLogOut /> <span>Sign Out</span></li>
                      </ul>
                    </div>
                  )}
                </div>
              ) : (
                <span
                  onClick={() => {
                    openDialog('login');
                    setIsOpen(false);
                  } }
                  className={`cursor-pointer p-2 ${isActive('/login') ? 'text-Green-400 underline' : ''} hover:text-Green-400 transition-colors duration-300`}
                >
                  ACCOUNT
                </span>
              )}
            </li>

          </ul>
        </motion.div>
      </div>

      <div className={`lg:flex max-sm:hidden w-full xl:text-lg items-center px-48 justify-between xl:px-20 z-30 fixed 
      ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'} 
      ${isPropertyPage ? 'text-black shadow-lg bg-white' : isScrolled ? 'text-[#545350]' : 'text-white'}`}

      >
        <div>
          <img src={isScrolled || isPropertyPage ? balcklogo : logo} width={200} alt='logo' className='xl:w-56 cursor-pointer' onClick={() => navigate('/')}></img>
        </div>
        <ul className={`flex items-center gap-14  text-base font-medium m-0`}>
          <li onClick={() => navigate('/buy')} className='cursor-pointer hover:text-Stone-800'>BUY</li>
          <li className='cursor-pointer' onClick={() => navigate('/sell')}>SELL</li>
          <li className='cursor-pointer' onClick={() => navigate('/ourteam')}>OUR TEAM</li>
          <li className='cursor-pointer' onClick={() => navigate('/ourstory')}>OUR STORY</li>
          <li className='cursor-pointer' onClick={() => navigate('/blog')}>BLOG</li>
          <li className='cursor-pointer' onClick={() => navigate('/contact')}>CONTACT</li>
        </ul>

        {isAuthenticated ? (
          <div className='p-2 w-60  text-base relative ps-20'>
            <img
              loading="lazy"
              src={userDetails?.photoUrl}
              alt="User Avatar"
              className="rounded-full w-10 h-10  cursor-pointer"
              onClick={() => setShowProfile(!showProfie)}
              onError={(e) => {
                console.error("Image failed to load:", userDetails?.photoUrl, e);
              }} />
            {showProfie && (
              <div className='absolute rounded-md border top-14'>
                <ul className='bg-white text-black rounded-md m-0'>
                  {/* <li className='flex items-center gap-1 p-2 border-b border-gray-100 cursor-pointer hover:bg-gray-200'><FaRegUserCircle /> <span>{userDetails?.displayName}</span></li> */}
                  <li className='flex items-center gap-1 p-2 border-b border-gray-100 cursor-pointer hover:bg-gray-200' onClick={() => { logout(); setShowProfile(false); }}><FiLogOut /> <span>Sign Out</span></li>
                </ul>
              </div>
            )}
          </div>
        ) : (
          <ul className='flex gap-2 items-center'>
            <li>
              <button
                className={`p-3 rounded-full w-24 xl:w-20 xl:p-2 border border-[#F2CC2F] hover:bg-black hover:text-white`}
                onClick={() => openDialog('login')}
              >
                Sign in
              </button>
            </li>
            {/* <li>
              <button
                className='p-3 bg-white rounded-full text-[#545350] hover:bg-black hover:text-white w-24 xl:w-20 xl:p-2 border border-[#F2CC2F]'
                onClick={() => openDialog('signup')}
              >
                Sign up
              </button>
            </li> */}
          </ul>
        )}


      </div>
      <AuthDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        isLogin={isLogin}
        switchForm={switchForm}
        initialForm={initialForm}
      />
    </div>



  );
};

export default HeaderComponent;