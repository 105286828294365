import React, { createContext, useState } from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const [propertyType, setPropertyType] = useState(['']);
    const [bedrooms, setBedrooms] = useState(null);
    const [bathrooms, setBathrooms] = useState(null);
    const [parking, setParking] = useState(null);
    const [price, setPrice] = useState([0, 3200000]);
    const [sqft, setSqft] = useState([0, 8000]);
    const [status, setStatus] = useState('A');
    const [maintenanceFee, setMaintenanceFee] = useState([0, 1000]);
    const [minPrice, setMinPrice] = useState([]);
    const [maxPrice, setMaxPrice] = useState([]);
    const [mlsNumber, setMlsNumber] = useState('');
    const [purchaseType, setPurchaseType] = useState(['Buy']);
    const [location, setLocation] = useState('Toronto');
    const [currentView, setCurrentView] = useState('list');
    const [sortType, setSortType] = useState('')

    return (
        <FilterContext.Provider value={{
            propertyType,
            setPropertyType,
            bedrooms,
            setBedrooms,
            bathrooms,
            setBathrooms,
            parking,
            setParking,
            price,
            setPrice,
            sqft,
            setSqft,
            status,
            setStatus,
            maintenanceFee,
            setMaintenanceFee,
            minPrice,
            setMinPrice,
            maxPrice,
            setMaxPrice,
            mlsNumber,
            setMlsNumber,
            purchaseType,
            setPurchaseType,
            location,
            setLocation,
            currentView,
            setCurrentView,
            sortType,
            setSortType
        }}>
            {children}
        </FilterContext.Provider>
    );
};