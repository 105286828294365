import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaLock, FaUser, FaFacebookSquare } from 'react-icons/fa';
import { FcGoogle, } from 'react-icons/fc';
import { IoClose } from 'react-icons/io5';
import { googleLogin, signInService, loginService } from '../service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../hooks/AuthContext';
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { FacebookAuthProvider } from "firebase/auth";
import { auth } from '../firebase/firebase';



const AuthDialog = ({ isOpen, onClose, initialForm = 'login' }) => {
  const { login, logout, } = useAuth();
  const [isLogin, setIsLogin] = useState(initialForm === 'login' || initialForm === 'signin');
  const [loginFormdata, setLoginFormData] = useState({
    email: '',
    password: '',
  });
  const [signInFormData, setsignInFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirm_pass: '',
  });
  const [error, setError] = useState()
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    setIsLogin(initialForm === 'login' || initialForm === 'signin');
  }, [initialForm]);

  if (!isOpen) return null;

  const toggleForm = () => setIsLogin(!isLogin);

  // Handle Google login success
  const handleLoginSuccess = async (googleToken) => {
    try {
      const data = { token: googleToken };
      if (isLogin) {
        const response = await loginService(data);
        console.log('Login success:', response);
      } else {
        const response = await signInService(data);
        console.log('Sign-up success:', response);
      }
    } catch (error) {
      console.error('Google login failed:', error.message);
    }
  };

  const handleLogin = async (e, providerName) => {
    e.preventDefault();
    if (providerName === 'google') {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        if(result){
          const loginPayload = { googleId: result.user.uid, email: result.user.email, roles: ['user'], project:"JD" }

          // const response = await loginService(loginPayload);
          // console.log('Login success:', response);
          console.log('Login success:', result);
          localStorage.setItem('token', result.user.accessToken);
          localStorage.setItem('user', JSON.stringify(result.user.reloadUserInfo))
        }
        // Cookies.set('accessToken', result.user.accessToken, { expires: 1 });
        // Cookies.set('refreshToken', result.user.stsTokenManager.refreshToken, { expires: 1 });
        // Cookies.set('userDetails', JSON.stringify(result.user.reloadUserInfo), { expires: 1 });
        onClose();
        login(result.user.accessToken);
      } catch (error) {
        console.error(error);
      }
    }
    if (providerName === 'facebook') {
      try {
        const provider = new FacebookAuthProvider();
        const result = await signInWithPopup(auth, provider);
        // Cookies.set('accessToken', result.user.accessToken, { expires: 1 });
        // Cookies.set('refreshToken', result.user.stsTokenManager.refreshToken, { expires: 1 });
        // Cookies.set('userDetails', JSON.stringify(result.user.reloadUserInfo), { expires: 1 });
        // onClose();
      }
      catch (error) {
        console.error(error);
      }
    }
  };

  // Handle Google login failure
  const handleLoginFailure = (error) => {
    console.error('Google login failed', error);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isLogin) {
      const loginPayload = {
        email: loginFormdata.email,
        password: loginFormdata.password
      }
      try {
        const response = await loginService(loginPayload);
        if (response)
          login()
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', loginFormdata.email)
        toast.success('Login Succefully', {
          position: "top-right",
          autoClose: 3000,
        })
        setTimeout(() => {
          onClose();

        }, 3000)

      } catch (error) {
        console.log("error", error)
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 3000,
        })
        console.error('Login failed:', error);
      } finally {
        setLoginFormData({ email: '', password: '' })
      }
    } else {
      if (!validateEmail(signInFormData.email)) {
        console.log(validateEmail(signInFormData.email))
        return setError('email')
      }
      const signInPayload = {
        username: signInFormData.name,
        email: signInFormData.email,
        password: signInFormData.password
      }
      try {
        const response = await signInService(signInPayload);

        if (response) {
          toast.success('User Created SuccessFully', {
            position: 'top-right',
            autoClose: 2000
          })
          setIsLogin(!isLogin)
        }
        console.log('Sign-up response:', response);
      } catch (error) {
        console.error('Sign-up failed:', error.message);
      } finally {
        setsignInFormData({ email: '', password: '', confirm_pass: '', name: '' })
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
        <button onClick={onClose} className="absolute top-3 right-3 text-gray-400 hover:text-gray-600">
          <IoClose />
        </button>

        <h2 className="text-2xl font-semibold text-gray-800 text-start mb-1">
          {/* {isLogin ? 'Welcome back' : ' */}
          Welcome to Johnston & Daniel
          {/* '} */}
        </h2>
        <p className="text-start text-gray-500 mb-6">
          {/* {isLogin ? ' */}
          Get ready to explore!
          {/* ' : 'Create an account to join us!'} */}
        </p>

        {/* Form */}
        <form >
          {/* {!isLogin && (
            <div className="mb-4 relative">
              <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Your Name"
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
                value={signInFormData.name}
                onChange={(e) => setsignInFormData({ ...signInFormData, name: e.target.value })}
              />
            </div>
          )}

          <div className="mb-4 relative">
            <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="email"
              placeholder="Your Email"
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
              value={isLogin ? loginFormdata.email : signInFormData.email}
              onChange={(e) => {
                if (isLogin) {
                  setLoginFormData({ ...loginFormdata, email: e.target.value });
                } else {
                  setsignInFormData({ ...signInFormData, email: e.target.value });
                }
              }}
            />

          </div>
          {error === 'email' && (
            <p className='text-xs text-start text-Red-600'>*enter a valid maild id</p>
          )}

          <div className="mb-4 relative">
  <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
  <input
    type={showPassword ? 'text' : 'password'}
    placeholder="Your Password"
    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
    value={isLogin ? loginFormdata.password : signInFormData.password}
    onChange={(e) => {
      if (isLogin) {
        setLoginFormData({ ...loginFormdata, password: e.target.value });
      } else {
        setsignInFormData({ ...signInFormData, password: e.target.value });
      }
    }}
  />
  <div onClick={() => setShowPassword(!showPassword)} className="cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2">
    {showPassword ? <FaRegEyeSlash className='text-gray-400'/> : <FaRegEye className='text-gray-400'/>}
  </div>
</div>


          {!isLogin && (
            <div className="mb-4 relative">
            <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
              value={signInFormData.confirm_pass}
              onChange={(e) =>
                setsignInFormData({ ...signInFormData, confirm_pass: e.target.value })
              }
            />
            <div
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              {showConfirmPassword ? <FaRegEyeSlash className='text-gray-400'/> : <FaRegEye className='text-gray-400'/>}
            </div>
          </div>
          )} */}

          {/* <button
            type="submit"
            className="w-full py-2 mb-4 font-semibold text-white bg-Stone-600 rounded hover:bg-Stone-700"
          >
            {isLogin ? 'LogIn' : 'SignUp'}
          </button> */}

          {/* Google Login Button */}
          <div className=" flex justify-center mb-1">
            <button onClick={(e) => handleLogin(e, 'google')}
              className="flex items-center gap-1 justify-center w-full rounded shadow-lg  py-2 bg-white text-gray-800 border border-gray-400 hover:bg-blue-300 hover:text-black">
              <FcGoogle size={22} /> 
              {/* {isLogin ? " */}
              Sign in with Google
              {/* " : "SignUp"} */}
            </button>
          </div>

          <p className='text-base text-gray-400 mb-1'>or</p>

          {/* Facebook Login Button */}
          <div className="mb-6 flex justify-center">
            <button onClick={(e) => handleLogin(e, 'facebook')}
              className="flex items-center gap-1 justify-center w-full rounded shadow-lg py-2 bg-blue-900  text-white">
              <FaFacebookSquare size={22} /> 
              {/* {isLogin ? " */}
              Sign in with Facebook
              {/* " : "SignUp"} */}
            </button>
          </div>
        </form>

        {/* Toggle between Log In and Sign Up */}
        {/* <p className="text-center text-gray-600 mt-6">
          {isLogin ? (
            <>
              Doesn’t have an account?{' '}
              <button onClick={toggleForm} className="text-blue-500 hover:underline">
                Sign up now
              </button>
            </>
          ) : (
            <>
              Already have an account?{' '}
              <button onClick={toggleForm} className="text-blue-500 hover:underline">
                Log in
              </button>
            </>
          )}
        </p> */}
      </div>
      <ToastContainer />
    </div>
  );
};

export default AuthDialog;
