// import React from 'react'
// import Profile from '../components/Profile'
// import Projects from '../components/Projects'
// import "../App.css"
// import { useLocation } from 'react-router'
// import OurStoryProfile from './ourStoryProfile'
// function ProfilePage() {

//   const location = useLocation()
//   return (
//     <div className="container grid grid-cols-8  h-screen gap-8 pt-24">
//     <div className="profile col-span-5 bg-white text-white p-4 rounded-lg overflow-scroll shadow-md mt-5  mb-5">

//       {location.pathname === "/ourTeam/:id" ? <Profile /> : <OurStoryProfile/>}
      
//     </div>
//     <div className=" profile col-span-3 bg-white rounded-lg pt-7 shadow-md mb-5 mt-5 overflow-scroll">
//       <Projects />
//     </div>
//   </div>
//   )
// }

// export default ProfilePage  

import React from 'react';
import { useLocation } from 'react-router-dom'; // Updated import for useParams
import "../App.css";
import Profile from '../components/Profile';
import Projects from '../components/Projects';
import OurStoryProfile from './ourStoryProfile';


function ProfilePage() {
  const location = useLocation(); 

  const isOurTeamRoute = location.pathname.startsWith("/ourTeam/");
  return (
    <div className="container flex h-screen gap-8 pt-24 max-sm:flex-col md:flex-col lg:flex-row xl:flex md:h-[100%] lg:h-screen max-sm:h-[100%]">
      <div className="profile w-[70%]  bg-white text-white p-4 rounded-lg overflow-scroll shadow-md mt-5 max-sm:w-[100%] md:w-[100%] lg:w-[70%]">
        {isOurTeamRoute ? <Profile /> : <OurStoryProfile />}
      
      </div>

      <div className=" w-[30%] bg-white rounded-lg pt-7 shadow-md  mt-5 overflow-y-auto max-sm:w-[100%] md:w-[100%] lg:w-[30%]">
        <Projects />
      </div>
    </div>
  );
}

export default ProfilePage;
