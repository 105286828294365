import React from "react";
import img1 from '../assets/images/Luxuy-An-experience-not-a-pricepoint-image.jpg'
import { CiPlay1 } from "react-icons/ci";
import img2 from '../assets/images/Guesswork-simply-doesn-t-enter-into-it-image.webp';
import img3 from '../assets/images/The-art-of-print-and-digital-storytelling-image.webp';
import img4 from '../assets/images/Advanced-Analytics-Image.webp'
import img5 from '../assets/images/There-s-still-power-in-print-image.jpg';
import img6 from '../assets/images/Direct-response-personally-packaged-image.webp';
import img7 from '../assets/images/Homebuyers-now-search-online-first-image.webp';
import img8 from '../assets/images/Ultra-targeted-social-media-marketing.webp';
import img9 from '../assets/images/people-1-1-1.webp';
import img10 from '../assets/images/section_row_image_2-1-1-1.webp';
import img11 from '../assets/images/house-1900-1-1-1.webp';
import HeaderImg from "../assets/images/HEADER-IMAGE-2.webp"
import Background from "../assets/images/row_background_white-1-1-1.webp"
import MainHome from '../assets/images/main-home-bg-img-1-1-1.webp'
import FrstBackground from "../assets/images/background-pattern-gold-arch-1-1-1.svg"
import { color } from "framer-motion";
import { HiBars3BottomLeft } from "react-icons/hi2";
import { Link } from "react-router-dom";
// import { color } from "framer-motion";



const SellPage = () => {

    const fontStyle = {
        h3: {
          fontSize: "45px",
          fontFamily: '"Bodoni Moda", "Sans-serif"',
          color:"black"
        },
        h2: {
            color:" #FFFFFF",
            fontFamily: '"Bodoni Moda", "Sans-serif"',
            fontSize: "67px",
            fontWeight:" 400",
            lineHeight: "1.1em",
            textShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
          },
      };
    return (
        <div className="">
             <div className="relative bg-cover bg-center animate-fadeIn h-[650px] flex items-center  mb-20"  style={{
                        backgroundImage:
                            `url(${HeaderImg})`, 
                    }}>
             <div className="absolute inset-0 bg-black bg-opacity-50"></div>

                       <div className="relative text-center w-full">

                       
              
                <h2 style={fontStyle.h2}>Our Services</h2>
           
                       </div>

         
          </div>
          <section className=" bg-center bg-no-repeat bg-auto" style={{ backgroundImage:`url(${FrstBackground})`}}>
            <div className=" pl-10 mb-10 max-sm:p-5">
                <h1 className="text-black" style={fontStyle.h3}>The Standard of Excellence</h1>
                <p className="text-lg mt-4 text-gray-600">JOHNSTON & DANIEL INTRODUCED LUXURY SERVICE TO THE MARKETPLACE IN 1950, AND WE’VE <br />BEEN REFINING AND ADVANCING IT EVER SINCE.</p>
            </div>
        </section>
           <section  className=" " style={{backgroundImage:`url(${Background})`}}>
            <div className="flex flex-col lg:flex-row items-center lg:items-start max-w-7xl mx-auto px-4 lg:px-12 py-8">
                {/* Image Section */}
                <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
                    <img
                        src={img1} // Replace with the actual image URL
                        alt="Luxury Property"
                        className="rounded-lg shadow-lg"
                    />
                </div>

                {/* Text Section */}
                <div className="lg:w-1/2 w-full lg:pl-12  text-left">
                    <h3  className=" lg:text-xl ">
                        Luxury. An experience, not a price point.
                    </h3>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        Who counts on J&D? We set out in 1950 to work with clients who
                        understand and inhabit luxury – in all its manifestations. Those
                        whose real estate interests might span urban, rural, lakeside,
                        mountainside to offshore. Those in the creative and athletic arts,
                        finance and entrepreneurial to C-suite superstars. What they all have
                        in common is a lifestyle characterized by hard work, hard play and no
                        room for second-best.
                    </p>
                    <p className="text-base text-gray-600 leading-relaxed">
                        Why J&D was formed. Ours was an industry ripe for elevation. We began
                        by putting ourselves in our clients’ shoes and asking what needed
                        changing. We decided that rather than run on the “always be closing”
                        ethic used by competitors, we’d instead “always be anticipating.” If
                        we didn’t listen, we’d never learn. If we didn’t ask, we couldn’t
                        understand. If we failed to make the experience all about our
                        clients, we’d failed them.
                    </p>
                </div>
            </div>
            </section>
            <div className="flex flex-col lg:flex-row items-center lg:items-start max-w-7xl mx-auto px-4 lg:px-12  gap-3  py-8">


                {/* Text Section */}
                <div  className="lg:w-1/2 w-full text-left ">
                    <h2 style={fontStyle.h3} className="">
                        Guesswork simply doesn’t enter into it.
                    </h2>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        Our version of bespoke. Many businesses flash the B-word; few take the time or possess the nuance to deliver it in full to their high-expectation clientele. Our people enter each relationship with three questions in mind: How will we enable this client to maximize the value of their real estate investment? What must we learn to guide them toward the most astute decision? And how will we best deliver on their expectations of us in terms of discretion, trustworthiness and advice
                    </p>
                    <p className="text-base text-gray-600 leading-relaxed">
                        The luxury property seller’s expectations. They demand sophisticated, high-touch local and global marketing to achieve their aim. International reach is vital. Exclusive, or non-MLS, listings may be preferred by clients requiring greater discretion. J&D delivers it all, and like no other
                    </p>
                </div>

                {/* Image Section */}
                <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
                    <img
                        src={img2} // Replace with the actual image URL
                        alt="Luxury Property"
                        className="rounded-lg shadow-lg"
                    />
                </div>
            </div>
         <section  className=" "  style={{backgroundImage:`url(${Background})`}}>
            <div className="flex  flex-col-reverse lg:flex-row items-center lg:items-start max-w-7xl mx-auto px-4 lg:px-12 py-8 ">
                {/* Image Section */}
                <div className="lg:w-1/2 w-full my-5">
                    <img
                        src={img3} // Replace with the actual image URL
                        alt="Luxury Property"
                        className="rounded-lg shadow-lg "
                    />
                </div>

                {/* Text Section */}
                <div className="lg:w-1/2 w-full lg:pl-12  text-left">
                    <h2 style={fontStyle.h3} className="">
                        The art of digital and print storytelling.
                    </h2>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        Our digital brochures use interactive storytelling to create immersive experience for homebuyers. Engage visitors with slideshows, virtual tours, and dazzling video in an inviting and elegant package.
                    </p>
                    <p className="text-base text-gray-600 leading-relaxed">
                        Our print brochures feature compelling designs, rich narratives and vivid images to tell your home’s story, printed in-house through our professional, state-of-the-art J&D Marketing & Design Studio on plush silk stock for a luxurious look and feel.
                    </p>
                </div>
            </div>
            </section>
            <div className="flex flex-col lg:flex-row items-center lg:items-start max-w-7xl mx-auto px-4 lg:px-12 py-8 ">


                {/* Text Section */}
                <div className="lg:w-1/2 w-full text-left">
                    <h2  style={fontStyle.h3} className="">
                        Advanced analytics.
                    </h2>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        With our comprehensive platform, you’ll always know how your property performs online. Discover how many unique visitors view your listing and measure their level of engagement. See the bigger picture with informative charts, heat maps and indispensable reports.
                    </p>

                </div>

                {/* Image Section */}
                <div className="lg:w-1/2 w-full mb-8 lg:mb-0 lg:pl-5 pl-0">
                    <img
                        src={img4} // Replace with the actual image URL
                        alt="Luxury Property"
                        className="rounded-lg shadow-lg"
                    />
                </div>
            </div>
            <section className=""  style={{backgroundImage:`url(${Background})`}}>
            <div className="flex flex-col-reverse lg:flex-row items-center lg:items-start max-w-7xl mx-auto px-4 lg:px-12 py-8 ">
                {/* Image Section */}
                <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
                    <img
                        src={img5} // Replace with the actual image URL
                        alt="Luxury Property"
                        className="rounded-lg shadow-lg"
                    />
                </div>

                {/* Text Section */}
                <div className="lg:w-1/2 w-full lg:pl-12 text-left">
                    {/* <h2 style={fontStyle.h3} className="text-3xl lg:text-4xl font-bold leading-tight mb-6">
                        There's still power in print.
                    </h2> */}
                    <h2 style={fontStyle.h3} className="">
                    There's still power in print.
                    </h2>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        We feature every newly listed property in each Friday’s Globe and Mail newspaper, which offers a weekday readership average of 899,000 and 1.6 million on weekends. We can also feature your property via campaigns in other national papers and upscale local publications read by your target demographic in the country’s most sought-after markets. Our advertising power combines the resources of Johnston & Daniel with the vast Royal LePage network.
                    </p>

                </div>
            </div>
            </section>
            <div className="flex flex-col gap-3 lg:flex-row items-center lg:items-start max-w-7xl mx-auto px-4 lg:px-12 py-8">


                {/* Text Section */}
                <div className="lg:w-1/2 w-full text-left">
                    <h2 style={fontStyle.h3} className="">
                        Direct response, personally packaged
                    </h2>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        J&D combines leading-edge creative and print production services to develop high-impact direct mail pieces, and we place it straight into the hands of targeted demographics in the most dynamic markets. Through the J&D Marketing & Design Studio, we ensure a consistently sophisticated, high-end look and feel.
                    </p>

                </div>

                {/* Image Section */}
                <div className="lg:w-1/2 w-full mb-8 lg:mb-0 lg:pl-5 pl-0">
                    <img
                        src={img6} // Replace with the actual image URL
                        alt="Luxury Property"
                        className="rounded-lg shadow-lg"
                    />
                </div>
            </div>
        <section className=" "  style={{backgroundImage:`url(${Background})`}}>
            <div className="flex flex-col-reverse lg:flex-row items-center lg:items-start max-w-7xl mx-auto px-4 lg:px-12 py-8 ">
                {/* Image Section */}
                <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
                    <img
                        src={img7} // Replace with the actual image URL
                        alt="Luxury Property"
                        className=""
                    />
                </div>

                {/* Text Section */}
                <div className="lg:w-1/2 w-full lg:pl-12 text-left">
                    <h2 style={fontStyle.h3} className="">
                        The importance of SEO in the online era.
                    </h2>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        The world we live in today is a digital one and searching for a home is no different. In 1981, 22 percent of homebuyers read newspaper ads to find a home and 8 percent used friends as an information source. In 2018, 48.4 percent of all buyers started their search online to find a property.*
                    </p>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        Online searching maximizes the ability to compare and contrast homes on the market and buyers now primarily search for property online first, before connecting with a Realtor. Our focus on digital exposure and advertising ensures that properties listed with Johnston & Daniel have prominent presence when consumers are searching for their next home.
                    </p>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        *National Association of REALTORS® Research Group
                    </p>


                </div>
            </div>
        </section>
            <div className="flex flex-col lg:flex-row items-center lg:items-start max-w-7xl mx-auto px-4 lg:px-12 py-8">


                {/* Text Section */}
                <div className="lg:w-1/2 w-full text-left">
                    <h2 style={fontStyle.h3} className="">
                        Ultra-targeted social media marketing.
                    </h2>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        In social media marketing, rather than “spray and pray”, J&D harnesses Facebook, Instagram, LinkedIn and Twitter to micro-target listings and information to appropriate segments of our large following. Using high-end photography, video and articles, we incorporate interior images, press mentions and proprietary videos across all digital platforms to nurture influencers. Buying or selling, word travels fast.
                    </p>

                </div>

                {/* Image Section */}
                <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
                    <img
                        src={img8} // Replace with the actual image URL
                        alt="Luxury Property"
                        className="rounded-lg shadow-lg"
                    />
                </div>
            </div>
        <section className=" bg-white"  style={{
  backgroundImage: `url(${MainHome})`,

}}>
            <div className="flex flex-col lg:flex-row items-center lg:items-start max-w-7xl mx-auto px-4 lg:px-12 py-16 ">


                {/* Text Section */}
                <div className="lg:w-1/2 w-full text-center lg:text-left my-auto">
                    <h2 style={fontStyle.h3} className="">
                        Why Choose us
                    </h2>
                    <p className="text-base text-gray-600 leading-relaxed mb-6">
                        An experience, not a price point.
                    </p>
                    <div className="flex gap-10 text-start">
                        <div>
                            <h3 style={{fontSize:"20px",fontWeight:"500px"}} className="">30+ years</h3>
                            <p style={{color:"#464646",fontSize:"14px"}}>of Real Estate Experience</p>
                        </div>
                        <div>
                            <h3 style={{fontSize:"20px",fontWeight:"500px"}} className="">2012 - 2024</h3>
                            <p style={{color:"#464646",fontSize:"14px"}}>Realtors®️ Sales Award</p>
                        </div>          
                    </div>

                </div>

                {/* Image Section */}
                <div className="lg:w-1/2 w-full mb-8 lg:mb-0 relative">
                    <img
                        src={img9} // Replace with the actual image URL
                        alt="Luxury Property"
                        className="rounded-lg shadow-lg h-[500px] object-cover"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <CiPlay1 className="text-4xl text-black bg-white bg-opacity-50 rounded-full p-4 cursor-pointer" 
                        size={60}
                        onClick={() => window.open('https://youtu.be/b0TZN-YaqJ0', '_blank')}/>
                    </div>
                </div>
            </div>
            </section>
            <div className="relative flex  h-[700px] max-sm:flex-col ">
                        {/* Sellers Section */}
                        <div
                            className="w-1/2 h-full bg-cover bg-center relative max-sm:w-[100%]"
                            style={{
                            backgroundImage: `url(${img10})`,
                            }}
                        >
                            {/* Overlay */}
                            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                            {/* Content */}
                            <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white px-4">
                            <p className="text-lg font-medium">For Sellers</p>
                            <h1 className="text-3xl mt-2 font-bold">Are You Selling Your Home?</h1>
                            {/* <Link to='/home-valuation'> */}
                            <button  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="mt-4 bg-transparent border border-white text-white px-4 py-2 rounded-lg hover:bg-white hover:text-black transition duration-300">
                                GET ESTIMATION
                            </button>
                            {/* </Link> */}
                            </div>
                        </div>

                        {/* Buyers Section */}
                        <div
                            className="w-1/2 h-full bg-cover bg-center relative max-sm:w-[100%]"
                            style={{
                            backgroundImage: `url(${img11})`,
                            }}
                        >
                            {/* Overlay */}
                            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                            {/* Content */}
                            <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white px-4">
                            <p className="text-lg font-medium">For Buyers</p>
                            <h1 className="text-3xl mt-2 font-bold">Are You Buying Your Home?</h1>
                            <Link to='/buy'>
                            <button className="mt-4 bg-transparent border border-white text-white px-4 py-2 rounded-lg hover:bg-white hover:text-black transition duration-300"
                             >
                                SEARCH LISTINGS
                            </button>
                            </Link>
                            </div>
                        </div>
            </div>




        </div>
    )
}

export default SellPage