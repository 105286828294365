import React, { useEffect, useState } from "react";

import { GrLocation } from "react-icons/gr";
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import LoaderCard from "../components/loader";
import { getListings } from "../service";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";




function Terms() {
    const [similarListings, setSimilarListings] = useState([]);
    const [similarlistingLoading, setSimilarListingLoading] = useState(false);
    // const [currentIndex, setCurrentIndex] = useState(0);
  
    
    
  
    // const sliderSettings = {
    //   infinite: true,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   arrows: false,
    //   dots: false,
    //   autoplay: false,
    //   initialSlide: 0,
    //   afterChange: (index) => setCurrentIndex(index),
    // };
  
    useEffect(() => {
      getFeatureListings();
    }, []);
  
    const getFeatureListings = async () => {
      setSimilarListingLoading(true);
      const filterQuery =
        "&propertyType=Detached&minBeds=5&minBaths=5&minPrice=5000000&maxPrice=10000000";
      try {
        const result = await getListings(1, 3, filterQuery);
        setSimilarListings(result.data.listings);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setSimilarListingLoading(false);
      }
    };
    // const formatDate = (dateString) => {
    //   const date = new Date(dateString);
    //   const options = { day: "2-digit", month: "short", year: "numeric" };
    //   const formattedDate = date.toLocaleDateString("en-GB", options);
    //   return formattedDate;
    // };
  
    const formatPrice = (price) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(price);
    };
  return (
    <div className="lg:pt-24 pt-16 bg-gray-50 pb-5  lg:px-24 px-5">
    <div className="  mt-32 flex lg:flex-row flex-col gap-10 lg:items-start items-center" style={{ marginTop: "50px" }}>
      <div className="lg:w-[70%] w-full">
        <div className="mb-6">
          <h1
            className=" text-center"
            style={{ fontFamily: "Frank Ruhl Libre", fontSize:"34px" ,fontWeight:"34px"}}
          >
            Accessibility
          </h1>
        </div>
    <div class="single-content text-center flex flex-col gap-6">

      <ul className="flex flex-col justify-center items-center">
        <Link to="/Accessibility-for-Ontarians-with-Disabilities-Act">
      <li className="text-Green-800">Accessibility for Ontarians with Disabilities Act (AODA)</li>
        </Link>
        <Link to='/Accessibility-for-Persons-with-Disabilities-Policy'>
      <li className="text-Green-800">Accessibility for Persons with Disabilities Policy</li>
        </Link>
        <Link to='/Integrated-Accessibility-Standards-Policy'>
      <li className="text-Green-800">Integrated Accessibility Standards Policy</li>
        </Link>
        <Link to='/Multi-Year-Plan'>
      <li className="text-Green-800">Multi-Year Plan</li>
        </Link>
      </ul>
    </div>
      </div>
      <div className="lg:w-[30%] w-full pt-2 px-4 shadow-lg h-auto">
        <div className="mx-3">
          <h1
            className="text-3xl font-medium text-start mt-4"
            style={{ fontFamily: "Frank Ruhl Libre" }}
          >
            Latest Listings
          </h1>
        </div>
        <div className="p-1 px-3">
          <div className="flex justify-between items-center p-2">
            
          </div>
          {similarlistingLoading ? (
            Array.from({ length: 1 }).map((_, index) => (
              <LoaderCard key={index} />
            ))
          ) : (
            <div>
              {similarListings === 0 ? (
                <div>...Oops no similar data found </div>
              ) : (
                <>
                  {similarListings.map((item, index) => (
                    <div key={index}>
                      <div className="h-[350px] cursor-pointer m-2 relative bg-cover bg-center rounded-2xl overflow-hidden" 
                      onClick={() =>
                        window.open(`/property/${item.mlsNumber}`,"_blank")
                      }
                      
                      >
                        <img
                          src={item.images[0]}
                          alt="home"
                          className="rounded-2xl w-full h-full object-cover transform hover:scale-105"
                          style={{
                            transition:
                              "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
                          }}
                          onClick={() =>
                            window.open(`/property/${item.mlsNumber}`,"_blank")
                          }
                        />
                        <div className="p-4 rounded-b-xl absolute top-[55%]  w-full">
                          <div className="w-full bg-white px-5 py-3 flex flex-col  rounded-xl gap-2 text-sm">
                            <div className="flex justify-start items-center">
                              <h2 className="text-lg text-[#006938] font-bold whitespace-nowrap">
                                {formatPrice(item.listPrice)}
                              </h2>
                            </div>
                            <div className="flex gap-1 items-center">
                              <GrLocation size={20} className="shrink-0" />
                              <h2
                                className="text-base max-2xl:text-sm  overflow-hidden text-start text-ellipsis font-normal whitespace-nowrap"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}
                              >{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
                            </div>
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-2">
                                       {item.details.numBedrooms > 0 && (
                               
                                                               <div className="flex gap-1 items-center">
                                                                 <IoBedOutline size={20} />
                                                                 <h4
                                                                   data-tooltip-id="my-tooltip"
                                                                   data-tooltip-content={
                                                                     item.details.numBedrooms
                                                                       ? item.details.numBedrooms
                                                                       : 0
                                                                   }
                                                                 >
                                                                   {item.details.numBedrooms
                                                                     ? item.details.numBedrooms
                                                                     : 0}
                                                                 </h4>
                                                               </div>
                                                               )}
                               
                                                               {item.details.numBathrooms > 0 && (
                               
                                                               <>
                                                               <span className="text-gray-300">|</span>
                                                               <div className="flex gap-1 items-center">
                                                                 <LiaBathSolid size={20} />
                                                                 <h4
                                                                   data-tooltip-id="my-tooltip"
                                                                   data-tooltip-content={
                                                                     item.details.numBathrooms
                                                                       ? item.details.numBathrooms
                                                                       : 0
                                                                   }
                                                                 >
                                                                   {item.details.numBathrooms
                                                                     ? item.details.numBathrooms
                                                                     : 0}
                                                                 </h4>
                                                               </div>
                                                               </>
                                                               )}
                                {/* <span className="text-gray-300">|</span>
                                <div className="flex gap-1 items-center">
                                  <BiArea size={20} />
                                  <h4
                                    className="truncate"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={
                                      item.lot.width
                                        ? `${item.lot.depth} x ${item.lot.width}`
                                        : "0 x 0"
                                    }
                                  >
                                    {item.lot.width
                                      ? `${item.lot.depth} x ${item.lot.width}`
                                      : "0 x 0"}
                                  </h4>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
  )
}

export default Terms